<template>
  <div class="page-all-content">
    <zhuanti-top-block></zhuanti-top-block>
    <zhuanti-map-block></zhuanti-map-block>
    <chzx-block></chzx-block>
    <jwzh-block></jwzh-block>
    <zzqs-block></zzqs-block>
    <hyfx-block></hyfx-block>
    <org-ana-block></org-ana-block>
    <czzc-block></czzc-block>
    <qych-block></qych-block>
  </div>
</template>

<script>
import api from "../../api";
import ZhuantiTopBlock from "./ZhuantiTopBlock";
import ZhuantiMapBlock from "./ZhuantiMapBlock";
import ChzxBlock from "./ChzxBlock";
import ZzqsBlock from "./ZzqsBlock";
import HyfxBlock from "./HyfxBlock";
import OrgAnaBlock from "./OrgAnaBlock";
import CzzcBlock from "./CzzcBlock";
import JwzhBlock from "./JwzhBlock";
import QychBlock from "./QychBlock";

export default {
  name: "ZhuantiHome",
  components: {
    QychBlock,
    JwzhBlock, CzzcBlock, OrgAnaBlock, HyfxBlock, ZzqsBlock, ChzxBlock, ZhuantiMapBlock, ZhuantiTopBlock},
  data(){
    return {
      data:null,
      cardList: [
        { id: 1, name: '公司A', company: true, entType: '1' },
        { id: 2, name: '公司B', company: false, entType: '0' },
        { id: 3, name: '公司C', company: true, entType: '1' },
        { id: 4, name: '公司D', company: true, entType: '1' },
        { id: 5, name: '公司E', company: false, entType: '0' },
        { id: 6, name: '公司F', company: true, entType: '1' },
        { id: 7, name: '公司G', company: true, entType: '1' },
        { id: 8, name: '公司H', company: false, entType: '0' },
        { id: 9, name: '公司I', company: true, entType: '1' },
        { id: 10, name: '公司J', company: false, entType: '0' },
        { id: 11, name: '公司K', company: true, entType: '1' },
        { id: 12, name: '公司L', company: false, entType: '0' }
      ]
    }
  },
  created() {
    this.loadData(1)
  },
  methods:{
    prev() {
      this.$refs.carousel.prev();
    },
    next() {
      this.$refs.carousel.next();
    },
    async loadData(page){
      this.data=(await api.listMof({page:page-1,size:10})).data
    }
  }
}
</script>

<style scoped>
.page-all-content{
  position: relative;  /* 确保容器有相对定位 */
  width: 100%;  /* 确保容器有足够的宽度 */
  height: 100%;  /* 确保容器有足够的高度，避免背景不完全显示 */
  z-index: 0;  /* 保证背景在内容下方 */
  padding-bottom: 40px;
}
.page-all-content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../assets/images/zhuanti/map-bg.png");
  background-size: 100% 100%;  /* 填充并拉伸背景图片 */
  opacity: 0.05;  /* 设置背景图片的透明度 */
  z-index: -1;  /* 保证背景在内容下方 */
}
</style>
