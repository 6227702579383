<template>
  <div>
    <div class="top-div-container">
      <div class="top-label-content">随着我国疫情防控进入“乙类乙管”常态化防控阶段，线下展览全面复苏，出国展览迎来新机遇。2023 年4月，国务院办公厅印发《关于推动外贸稳规模优结构的意见》，要求各地方和贸促机构、商协会进一步加大对外贸企业参加各类境外展会的支持力度，持续培育境外自办展会、扩大办展规模。</div>
      <div class="top-label1-content">——《2023中国展览经济发展报告》中国国际贸易促进委员会</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ZhuantiTopBlock"
}
</script>

<style scoped>
.top-div-container{
  width: 100%;
  height: 450px;
  background-image: url("../../assets/images/zhuanti/top-one-bg.png");
  background-size: 100% 100%;
}
.top-label-content{
  font-family: PingFang SC;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #FFFFFF;
  width: 45%;
  padding-top: 112px;
  padding-left: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-indent: 2em;
}
.top-label1-content{
  font-family: PingFang SC;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-align: right;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
  width: 45%;
  padding-top: 60px;
  padding-left: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
