<template>
  <div class="zhuanti-div-container">
    <zhuanti-title-block :title="'机构分析'"></zhuanti-title-block>
    <div class="jgfx-container">
      <div class="quote-container">
        <img src="../../assets/images/zhuanti/dhao-icon.png">
        经中国贸促会审批，全国共有<span style="color: red">71</span>家组展单位组织出国展览。其中，组展单位项目数量、组织企业参展数量、组展参展面积等指标显示、组展单位呈现头部集中趋势。</div>
      <div class="chart-ana-lr">
        <div class="table-title">2023 年中国出国参办展实施情况</div>
        <el-table
            :data="data"
            style="width: 100%;margin-bottom: 20px; max-height: 310px; overflow: auto;" :header-fixed="true"
            row-key="name"
            border
            :expand-row-keys="['按隶属关系分组','按注册登记类型分组','按行业分组']"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
          <el-table-column width="80" type="index" label="序号" :index="index">
          </el-table-column>
          <el-table-column
              prop="organizingUnit"
              label="组展单位">
          </el-table-column>
          <el-table-column
              prop="projectCount"
              label="项目数量(项)"
              width="280">
          </el-table-column>
          <el-table-column
              prop="projectRatio"
              label="比例"
              width="120">
          </el-table-column>
          <el-table-column
              prop="exhibitorCount"
              label="组织参展企业数量(家)"
              width="180">
          </el-table-column>
          <el-table-column
              prop="exhibitorRatio"
              label="比例"
              width="120">
          </el-table-column>
          <el-table-column
              prop="exhibitionArea"
              label="组织参展面积(平方米)"
              width="180">
          </el-table-column>
          <el-table-column
              prop="exhibitionAreaRatio"
              label="比例"
              width="120">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="org-source">数据来源：中国国际贸易促进委员会</div>
  </div>
</template>

<script>
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {BarChart} from "echarts/charts";
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);
import '../../echarts/map'
import ZhuantiTitleBlock from "./ZhuantiTitleBlock";

export default {
  name: "OrgAnaBlock",
  components: {
    ZhuantiTitleBlock
  },
  computed: {
    option(){
      return {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '会展面积统计',
            type: 'pie',
            radius: ['35%', '60%'],
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: true,
              overflow:'break'
            },
            data: this.data
          }
        ]
      }
    },
  },
  data() {
    const months=[]
    for(let month=1;month<=12;month++){
      months.push(month+'月')
    }
    return {
      year:new Date().getFullYear()+'',
      data: [
        {
          "serialNumber": 1,
          "organizingUnit": "浙江米奥兰特商务会展股份有限公司",
          "projectCount": 16,
          "projectRatio": 1.78,
          "exhibitorCount": 7229,
          "exhibitorRatio": 18.24,
          "exhibitionArea": 92070,
          "exhibitionAreaRatio": 16.09
        },
        {
          "serialNumber": 2,
          "organizingUnit": "西麦克国际展览有限责任公司",
          "projectCount": 76,
          "projectRatio": 8.44,
          "exhibitorCount": 2542,
          "exhibitorRatio": 6.41,
          "exhibitionArea": 40371.25,
          "exhibitionAreaRatio": 7.06
        },
        {
          "serialNumber": 3,
          "organizingUnit": "浙江远大国际会展有限公司",
          "projectCount": 105,
          "projectRatio": 11.67,
          "exhibitorCount": 2411,
          "exhibitorRatio": 6.08,
          "exhibitionArea": 36969.95,
          "exhibitionAreaRatio": 6.46
        },
        {
          "serialNumber": 4,
          "organizingUnit": "中国国际展览中心集团有限公司",
          "projectCount": 53,
          "projectRatio": 5.89,
          "exhibitorCount": 2319,
          "exhibitorRatio": 5.85,
          "exhibitionArea": 51477,
          "exhibitionAreaRatio": 9
        },
        {
          "serialNumber": 5,
          "organizingUnit": "贸促会纺织行业分会",
          "projectCount": 11,
          "projectRatio": 1.22,
          "exhibitorCount": 1981,
          "exhibitorRatio": 5,
          "exhibitionArea": 27620,
          "exhibitionAreaRatio": 4.83
        },
        {
          "serialNumber": 6,
          "organizingUnit": "贸促会化工行业分会",
          "projectCount": 27,
          "projectRatio": 3,
          "exhibitorCount": 1774,
          "exhibitorRatio": 4.48,
          "exhibitionArea": 20414.87,
          "exhibitionAreaRatio": 3.57
        },
        {
          "serialNumber": 7,
          "organizingUnit": "中国机电产品进出口商会",
          "projectCount": 54,
          "projectRatio": 6,
          "exhibitorCount": 1763,
          "exhibitorRatio": 4.45,
          "exhibitionArea": 31689,
          "exhibitionAreaRatio": 5.54
        },
        {
          "serialNumber": 8,
          "organizingUnit": "中国医药保健品进出口商会",
          "projectCount": 33,
          "projectRatio": 3.67,
          "exhibitorCount": 1665,
          "exhibitorRatio": 4.2,
          "exhibitionArea": 18546.55,
          "exhibitionAreaRatio": 3.24
        },
        {
          "serialNumber": 9,
          "organizingUnit": "中纺广告展览有限公司",
          "projectCount": 7,
          "projectRatio": 0.78,
          "exhibitorCount": 1541,
          "exhibitorRatio": 3.89,
          "exhibitionArea": 19014,
          "exhibitionAreaRatio": 3.32
        },
        {
          "serialNumber": 10,
          "organizingUnit": "商务部外贸发展事务局",
          "projectCount": 13,
          "projectRatio": 1.44,
          "exhibitorCount": 1456,
          "exhibitorRatio": 3.67,
          "exhibitionArea": 19285,
          "exhibitionAreaRatio": 3.37
        },
        {
          "serialNumber": 11,
          "organizingUnit": "贸促会机械行业分会",
          "projectCount": 58,
          "projectRatio": 6.44,
          "exhibitorCount": 1324,
          "exhibitorRatio": 3.34,
          "exhibitionArea": 21104.75,
          "exhibitionAreaRatio": 3.69
        },
        {
          "serialNumber": 12,
          "organizingUnit": "中国纺织品进出口商会",
          "projectCount": 6,
          "projectRatio": 0.67,
          "exhibitorCount": 1138,
          "exhibitorRatio": 2.87,
          "exhibitionArea": 11878,
          "exhibitionAreaRatio": 2.08
        },
        {
          "serialNumber": 13,
          "organizingUnit": "远大国际展览有限公司",
          "projectCount": 32,
          "projectRatio": 3.56,
          "exhibitorCount": 1075,
          "exhibitorRatio": 2.71,
          "exhibitionArea": 12724.5,
          "exhibitionAreaRatio": 2.22
        },
        {
          "serialNumber": 14,
          "organizingUnit": "福建荟源国际展览有限公司",
          "projectCount": 34,
          "projectRatio": 3.78,
          "exhibitorCount": 968,
          "exhibitorRatio": 2.44,
          "exhibitionArea": 10990,
          "exhibitionAreaRatio": 1.92
        },
        {
          "serialNumber": 15,
          "organizingUnit": "保利国际展览有限公司",
          "projectCount": 32,
          "projectRatio": 3.56,
          "exhibitorCount": 941,
          "exhibitorRatio": 2.37,
          "exhibitionArea": 10764,
          "exhibitionAreaRatio": 1.88
        },
        {
          "serialNumber": 16,
          "organizingUnit": "中国对外贸易中心集团有限公司",
          "projectCount": 11,
          "projectRatio": 1.22,
          "exhibitorCount": 738,
          "exhibitorRatio": 1.86,
          "exhibitionArea": 9400.82,
          "exhibitionAreaRatio": 1.64
        },
        {
          "serialNumber": 17,
          "organizingUnit": "上海市国际展览（集团）有限公司",
          "projectCount": 57,
          "projectRatio": 6.33,
          "exhibitorCount": 569,
          "exhibitorRatio": 1.44,
          "exhibitionArea": 8766.04,
          "exhibitionAreaRatio": 1.53
        },
        {
          "serialNumber": 18,
          "organizingUnit": "贸促会电子信息行业分会",
          "projectCount": 23,
          "projectRatio": 2.56,
          "exhibitorCount": 529,
          "exhibitorRatio": 1.33,
          "exhibitionArea": 7142,
          "exhibitionAreaRatio": 1.25
        },
        {
          "serialNumber": 19,
          "organizingUnit": "长城国际展览有限责任公司",
          "projectCount": 23,
          "projectRatio": 2.56,
          "exhibitorCount": 496,
          "exhibitorRatio": 1.25,
          "exhibitionArea": 6894.75,
          "exhibitionAreaRatio": 1.2
        },
        {
          "serialNumber": 20,
          "organizingUnit": "中国中轻国际控股有限公司",
          "projectCount": 11,
          "projectRatio": 1.22,
          "exhibitorCount": 486,
          "exhibitorRatio": 1.23,
          "exhibitionArea": 10971,
          "exhibitionAreaRatio": 1.92
        },
        {
          "serialNumber": 21,
          "organizingUnit": "中国食品土畜进出口商会",
          "projectCount": 11,
          "projectRatio": 1.22,
          "exhibitorCount": 380,
          "exhibitorRatio": 0.96,
          "exhibitionArea": 3668.77,
          "exhibitionAreaRatio": 0.64
        },
        {
          "serialNumber": 22,
          "organizingUnit": "中国五矿化工进出口商会",
          "projectCount": 12,
          "projectRatio": 1.33,
          "exhibitorCount": 373,
          "exhibitorRatio": 0.94,
          "exhibitionArea": 5378.5,
          "exhibitionAreaRatio": 0.94
        },
        {
          "serialNumber": 23,
          "organizingUnit": "中国电子国际展览广告有限责任公司",
          "projectCount": 19,
          "projectRatio": 2.11,
          "exhibitorCount": 371,
          "exhibitorRatio": 0.94,
          "exhibitionArea": 4204.5,
          "exhibitionAreaRatio": 0.73
        },
        {
          "serialNumber": 24,
          "organizingUnit": "中国国际贸易中心股份有限公司",
          "projectCount": 3,
          "projectRatio": 0.33,
          "exhibitorCount": 360,
          "exhibitorRatio": 0.91,
          "exhibitionArea": 6032,
          "exhibitionAreaRatio": 1.05
        },
        {
          "serialNumber": 25,
          "organizingUnit": "中国化工信息中心有限公司",
          "projectCount": 11,
          "projectRatio": 1.22,
          "exhibitorCount": 351,
          "exhibitorRatio": 0.89,
          "exhibitionArea": 4204.5,
          "exhibitionAreaRatio": 0.73
        },
        {
          "serialNumber": 26,
          "organizingUnit": "贸促会浙江省委员会",
          "projectCount": 10,
          "projectRatio": 1.11,
          "exhibitorCount": 324,
          "exhibitorRatio": 0.82,
          "exhibitionArea": 3957,
          "exhibitionAreaRatio": 0.69
        },
        {
          "serialNumber": 27,
          "organizingUnit": "江苏省商务厅",
          "projectCount": 2,
          "projectRatio": 0.22,
          "exhibitorCount": 288,
          "exhibitorRatio": 0.73,
          "exhibitionArea": 3495,
          "exhibitionAreaRatio": 0.61
        },
        {
          "serialNumber": 28,
          "organizingUnit": "中国机械通用零部件工业协会",
          "projectCount": 4,
          "projectRatio": 0.44,
          "exhibitorCount": 231,
          "exhibitorRatio": 0.58,
          "exhibitionArea": 2593.5,
          "exhibitionAreaRatio": 0.45
        },
        {
          "serialNumber": 29,
          "organizingUnit": "浙江中浙国际展览商务有限公司",
          "projectCount": 10,
          "projectRatio": 1.11,
          "exhibitorCount": 230,
          "exhibitorRatio": 0.58,
          "exhibitionArea": 2412,
          "exhibitionAreaRatio": 0.42
        },
        {
          "serialNumber": 30,
          "organizingUnit": "中国机械国际合作股份有限公司",
          "projectCount": 11,
          "projectRatio": 1.22,
          "exhibitorCount": 227,
          "exhibitorRatio": 0.57,
          "exhibitionArea": 2593.5,
          "exhibitionAreaRatio": 0.45
        }
      ],
      areas: ['华东','华南','西南','华北','西北','华中','东北'],
      months
    }
  },
  created() {
    this.init()
  },
  watch:{
    year(){
      this.init()
    }
  },
  methods: {
    async init() {
    },
  }
}
</script>

<style scoped>
.org-source{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
  color: #979797;
}
.jgfx-container{
  padding: 0px 0px 0px 0px;
  width: 100%;
}
.chart {
  height: 300px;
  width: 50%;
}
.chart-ana-lr{
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.table-title{
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #000000;
  padding: 16px 0px;
}
</style>
