<template>
  <div class="zhuanti-div-container">
    <zhuanti-title-block :title="'行业分析'"></zhuanti-title-block>
  <div class="zhuanti-hyfx-container">
    <div class="quote-container">
      <img src="../../assets/images/zhuanti/dhao-icon.png">
      综合贸易类、装备制造类和家居类展览数量最多，各自占比 <span style="color: red">16.58%</span>、<span style="color: red">16.58%</span>和 <span style="color: red">15.54%</span>，之和接近半数，对相应产品出口有一定积极影响。</div>
    <div class="chart-ana-lr">
      <div class="chart-ana-l">
        <div class="table-title">2023年境外自主办展行业分布</div>
        <v-chart class="chart" :option="option"/>
      </div>
      <div class="chart-ana-r">
        <div class="table-title">2023年主要出口商品</div>
        <el-table
            :data="data.slice(0,100)"
            style="width: 100%;margin-bottom: 20px; max-height: 300px; overflow: auto;" :header-fixed="true"
            row-key="name"
            border>
          <el-table-column
              prop="productName"
              label="商品名称">
          </el-table-column>
          <el-table-column
              prop="amountInBillion"
              label="金额(亿元)"
              width="180">
          </el-table-column>
          <el-table-column
              prop="growthRate"
              label="比上年增长(%)"
              width="120">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="hyfx-source">数据来源：2023中国展览数据统计报告、国家统计局</div>
  </div>
  </div>
</template>

<script>
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {BarChart} from "echarts/charts";
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);
import '../../echarts/map'
import ZhuantiTitleBlock from "./ZhuantiTitleBlock";

export default {
  name: "HyfxBlock",
  components: {
    ZhuantiTitleBlock,
    VChart,
  },
  computed: {
    option(){
      return {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '会展面积统计',
            type: 'pie',
            radius: ['35%', '60%'],
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: true,
              overflow:'break'
            },
            data: [
              { "name": "装备制造", "value": 31 },
              { "name": "家具", "value": 30 },
              { "name": "纺织服装与穿戴用品", "value": 27 },
              { "name": "建筑材料", "value": 15 },
              { "name": "能源", "value": 14 },
              { "name": "人生消费", "value": 12 },
              { "name": "造纸包装", "value": 10 },
              { "name": "信息电子", "value": 4 },
              { "name": "高新科技", "value": 4 },
              { "name": "农林牧渔", "value": 3 },
              { "name": "汽车", "value": 2 },
              { "name": "公共安全", "value": 1 },
              { "name": "环保", "value": 1 },
              { "name": "交通物流", "value": 1 },
              { "name": "文教", "value": 1 },
              { "name": "化工", "value": 1 },
              { "name": "零售", "value": 1 },
              { "name": "休闲娱乐", "value": 1 }
            ]
          }
        ]
      }
    },
  },
  data() {
    const months=[]
    for(let month=1;month<=12;month++){
      months.push(month+'月')
    }
    return {
      year:new Date().getFullYear()+'',
      data: [
        {
          "productName": "钢材",
          "amountInBillion": 5929,
          "growthRate": -3.4
        },
        {
          "productName": "纺织纱线、织物及其制品",
          "amountInBillion": 9454,
          "growthRate": -3.1
        },
        {
          "productName": "服装及衣着附件",
          "amountInBillion": 11206,
          "growthRate": -2.8
        },
        {
          "productName": "鞋靴",
          "amountInBillion": 3470,
          "growthRate": -8
        },
        {
          "productName": "家具及其零件",
          "amountInBillion": 4517,
          "growthRate": 0.2
        },
        {
          "productName": "箱包及类似容器",
          "amountInBillion": 2512,
          "growthRate": 9.3
        },
        {
          "productName": "玩具",
          "amountInBillion": 2858,
          "growthRate": -7.4
        },
        {
          "productName": "塑料制品",
          "amountInBillion": 7090,
          "growthRate": 1.4
        },
        {
          "productName": "集成电路",
          "amountInBillion": 9568,
          "growthRate": -5
        },
        {
          "productName": "自动数据处理设备及其零部件",
          "amountInBillion": 13187,
          "growthRate": -15.8
        },
        {
          "productName": "手机",
          "amountInBillion": 9797,
          "growthRate": 2.9
        },
        {
          "productName": "集装箱",
          "amountInBillion": 581,
          "growthRate": -39.8
        },
        {
          "productName": "液晶平板显示模组",
          "amountInBillion": 1873,
          "growthRate": 3.8
        },
        {
          "productName": "汽车（包括底盘）",
          "amountInBillion": 7165,
          "growthRate": 76.8
        }
      ],
      areas: ['华东','华南','西南','华北','西北','华中','东北'],
      months
    }
  },
  created() {
    this.init()
  },
  watch:{
    year(){
      this.init()
    }
  },
  methods: {
    async init() {
    },
  }
}
</script>

<style scoped>
.hyfx-source{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
  color: #979797;
}
.zhuanti-hyfx-container{
  padding: 0px 0px 0px 0px;
  width: 100%;
}
.chart {
  height: 300px;
  width: 50%;
}
.chart-ana-lr{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
}
.chart-ana-l{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
}
.chart-ana-r{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
}
.table-title{
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #000000;
  padding: 16px 0px;
}
</style>
