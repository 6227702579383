import { render, staticRenderFns } from "./QychBlock.vue?vue&type=template&id=1fb01adf&scoped=true&"
import script from "./QychBlock.vue?vue&type=script&lang=js&"
export * from "./QychBlock.vue?vue&type=script&lang=js&"
import style0 from "./QychBlock.vue?vue&type=style&index=0&id=1fb01adf&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fb01adf",
  null
  
)

export default component.exports