<template>
  <div class="page-content" v-if="zhanhui">
    <div class="zhanhui-details-header">
      <div class="zhanhui-details-header-left">
        <div><img class="zhanhui-details-header-logo" v-attachment-attr:src="zhanhui.logo"></div>
      </div>
      <div class="zhanhui-details-header-right">
        <div class="zhanhui-details-title">
          {{zhanhui.zhmc}}
          <span class="tag">{{zhanhui.sshy}}</span>
        </div>
        <div class="zhanhui-details-fields">
          <div class="info-field" style="width: 60%">
            <span class="info-field-label">举办时间：</span>
            <span class="info-field-value">{{zhanhui.jbkssj}} ~ {{zhanhui.jbjssj}}</span>
          </div>
          <div class="info-field" style="width: 40%">
            <span class="info-field-label">举办周期：</span>
            <span class="info-field-value">{{zhanhui.jbzq}}</span>
          </div>
          <div class="info-field" style="width: 60%">
            <span class="info-field-label">场馆：</span>
            <span class="info-field-value">{{zhanhui.jbcg}}</span>
          </div>
          <div class="info-field" style="width: 40%">
            <span class="info-field-label">展会面积：</span>
            <span class="info-field-value">{{zhanhui.zhmj}}平方米</span>
          </div>
          <div class="info-field" style="width: 100%" v-if="zhanhui.jbdw">
            <span class="info-field-label">主办单位：</span>
            <span class="info-field-value">{{zhanhui.jbdw}}</span>
          </div>
          <div class="info-field" style="width: 100%" v-if="zhanhui.cbdw">
            <span class="info-field-label">承办单位：</span>
            <span class="info-field-value">{{zhanhui.cbdw}}</span>
          </div>
        </div>
      </div>
      <div class="zhanhui-details-header-djs" v-if="pendingDays>0">
        <div>距离开展</div>
        <div class="zhanhui-details-header-djs-number">{{pendingDays}}</div>
        <div>天</div>
      </div>
    </div>
    <el-row :gutter="30">
      <el-col :span="14">
        <block title="展会简介">
          <div v-html="richTextToHtml(zhanhui.zhjj)" style="height: 360px;overflow: auto;"></div>
        </block>
      </el-col>
      <el-col :span="10">
        <zhanhui-news-block :abroad="1"></zhanhui-news-block>
      </el-col>
    </el-row>
    <el-row :gutter="30">
      <el-col :span="12">
        <zhanhui-cgtc-block :zhanhui="zhanhui"></zhanhui-cgtc-block>
      </el-col>
      <el-col :span="12">
        <zhanhui-hytc-block :zhanhui="zhanhui"></zhanhui-hytc-block>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Block from "./Block";
import ZhanhuiCgtcBlock from "./ZhanhuiCgtcBlock";
import ZhanhuiHytcBlock from "./ZhanhuiHytcBlock";
import AttachmentAttrDirective from "./AttachmentAttrDirective";
import ZhanhuiNewsBlock from "./ZhanhuiNewsBlock";
import api from "../api";
import HtmlUtils from "../utils/HtmlUtils";
import moment from "moment";
export default {
  name: "AbroadZhanhuiDetails",
  components:{Block,ZhanhuiCgtcBlock,ZhanhuiHytcBlock,ZhanhuiNewsBlock},
  directives: {
    'attachment-attr': AttachmentAttrDirective
  },
  data() {
    return {
      zhanhui:null
    }
  },
  created() {
    this.init()
  },
  computed:{
    pendingDays(){
      return this.zhanhui&&this.zhanhui.jbkssj?moment(this.zhanhui.jbkssj).diff(moment(),"days"):null
    }
  },
  methods: {
    async init(){
      this.zhanhui=(await api.getZhanhuiById(this.$route.query.id)).data
    },
    richTextToHtml(str){
      return HtmlUtils.richTextToHtml(str)
    }
  }
}
</script>

<style scoped>

</style>
