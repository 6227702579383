<template>
  <div id="app" :style="extraStyle">
    <div class="top-nav-container">
      <div class="top-nav">
<!--        <div class="top-nav-left">
          <img class="top-nav-logo" src="./assets/images/logoRET.png">
          <router-link v-for="item in menu" :key="item.name" :to="item.path"
                       :class="{'top-nav-menu':true,'top-nav-menu-selected':item===currentMenu}">{{item.name}}</router-link>
        </div>-->
        <!-- 遍历菜单 -->
        <div class="top-nav-left">
          <img class="top-nav-logo" src="./assets/images/logoRET.png">
          <div
              v-for="item in menu"
              :key="item.name"
              :class="{'top-nav-menu1':true,'top-nav-menu-selected1':item===currentMenu}"
              @mouseenter="showSubMenu(item)"
              @mouseleave="hideSubMenu(item)"
          >
            <!-- 主菜单 -->
            <router-link v-if="!(item.children && item.children.length>0)" :to="item.path" :class="{'top-nav-menu': true, 'top-nav-menu-selected': item === currentMenu}">
            {{ item.name }}
            </router-link>
            <a v-else href="javascript:void(0);"
               :class="{'top-nav-menu': true, 'top-nav-menu-selected': item === currentMenu}"
                @click.prevent="toggleSubMenu(item)"
            >
            {{ item.name }}
            </a>
            <!-- 子菜单 -->
            <div v-if="item.children && item.showSubMenu" class="sub-menu">
              <router-link
                  v-for="subItem in item.children"
                  :key="subItem.name"
                  :to="subItem.path"
                  class="sub-menu-item"
              >
                {{ subItem.name }}
              </router-link>
            </div>
          </div>
          </div>
<!--        <div class="top-nav-right">
          <img src="./assets/images/ss/index-1.png">
        </div>-->
      </div>
    </div>
    <div class="top-sub-nav" v-if="currentMenu&&currentMenu.subMenu">
      <router-link v-for="item in currentMenu.subMenu" :key="item.name" :to="item.path" class="top-sub-nav-link">{{item.name}}</router-link>
    </div>
    <router-view></router-view>
    <div class="footer">版权所有 Copyright © 2023-2024 北京融亦投智能科技有限公司</div>
  </div>
</template>
<script>
import './echarts/customed'

import { THEME_KEY } from "vue-echarts";


export default {
  name: 'App',
  provide: {
    [THEME_KEY]: 'customed'
  },
  data(){
    return {
      menu:[
        /*{
          name:'首页',
          path:'/'
        },*/
        {
          name:'会展库',
          path:'/zhanhui',
          subMenu:[
            {
              name:'国内会展综览',
              path:'/zhanhui/home'
            },
            {
              name:'国内行业会展分析',
              path:'/zhanhui/industry-home'
            },
            {
              name:'国内会展',
              path:'/zhanhui/list'
            },
            {
              name:'境外会展',
              path:'/zhanhui/abroad-list'
            },
            {
              name:'国内会议',
              path:'/huiyi/list'
            },
          ]
        },
        {
          name:'场馆库',
          path:'/changguan',
          subMenu:[
            {
              name:'国内场馆综览',
              path:'/changguan/home'
            },
            {
              name:'国内场馆',
              path:'/changguan/list'
            },
            {
              name:'境外场馆',
              path:'/abroadchangguan/list'
            },
            {
              name:'会议酒店',
              path:'/hxe-changguan/list'
            },
          ]
        },
        {
          name:'企业库',
          path:'/company',
          subMenu:[
            {
              name:'企业综览',
              path:'/company/home'
            },
            {
              name:'企业列表',
              path:'/company/list'
            },
          ]
        },
        {
          name:'会展招投标',
          path:'/ztb/list'
        },
        {
          name:'政策法规',
          path:'/zcfg',
          subMenu:[
            {
              name:'政策分析',
              path:'/zcfg/home'
            },
            {
              name:'政策信息',
              path:'/zcfg/list'
            },
          ]
        },
        {
          name:'行业研报',
          path:'/report',
          subMenu:[
            {
              name:'国内研报',
              path:'/report/list'
            },
            {
              name:'UFI研报',
              path:'/report/ufilist'
            },
            {
              name:'专题报告',
              path:'/report/speciallist'
            }
          ]
        },
        {
          name:'展前备案',
          path:'/mof/list'
        },
        {
          name:'宏观经济',
          path:'/macro-economy',
          subMenu:[
            {
              name:'经济指标',
              path:'/macro-economy/home'
            },
            {
              name:'统计公报',
              path:'/macro-economy/news'
            }
          ]
        },
        {
          name:'热点专题',
          path:'/zhuanti/home',
          children: [
            { name: '展会出海', path: '/zhuanti/home' },
          ],
          showSubMenu: false, // 控制子菜单显示
        },
      ]
    }
  },
  computed: {
    extraStyle () {
      if (this.$route.path.startsWith('/excerpt') || this.$route.path.startsWith('/memorabilia')) {
        return {paddingLeft: 0}
      } else {
        return null
      }
    },
    currentMenu(){
      for(const menu of this.menu){
        if(menu.path===this.$route.path){
          return menu
        }
        if(menu.subMenu&&menu.subMenu.some(i=>i.path===this.$route.path)){
          return menu
        }
      }
      return null
    }
  },
  methods: {
    toggleSubMenu(item) {
      // 切换子菜单显示状态
      item.showSubMenu = !item.showSubMenu;
    },
    // 显示子菜单
    showSubMenu(item) {
      if (item.children) {
        item.showSubMenu = true;
      }
    },
    // 隐藏子菜单
    hideSubMenu(item) {
      if (item.children) {
        item.showSubMenu = false;
      }
    },
  },
}
</script>
<style>
  #app{
    height: 100%;
    width: 100%;
    /*padding-left: calc(100vw - 100%);*/
    box-sizing: border-box;
    font-size: 16px;
    background-color: #F5F5F5;
  }
  /* 主菜单样式 */
  .top-nav-menu1 {
    position: relative;
    cursor: pointer;
  }
  /* 子菜单容器 */
  .sub-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
  }
  /* 子菜单项样式 */
  .sub-menu-item {
    padding: 10px 15px;
    color: #333;
    text-decoration: none;
    white-space: nowrap;
  }
  .sub-menu-item:hover {
    background-color: #f5f5f5;
    color: #007bff;
  }
  /* 鼠标悬停时的主菜单选中状态 */
  .top-nav-menu-selected1 {
    color: #007bff;
  }
</style>
