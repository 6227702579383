<template>
  <div class="zhuanti-div-container">
    <zhuanti-title-block :title="'境外参展政策'"></zhuanti-title-block>
    <div class="title-list-container">
      <div class="scrollable-list">
        <div v-for="(item, index) in showData.content" :key="index" shadow="never" class="title-item">
          <div class="title">
<!--            href="javascript:void(0)"-->
            <div class="czzc-label-title"><a :href="item.url" target="_blank" class="title-link">{{ item.title }}</a></div>
<!--            <div class="czzc-label-desc">{{ item.content }}</div>-->
          </div>
          <div class="metadata">
            <span class="author">{{ item.province }}</span>
            <span class="date">{{ item.time }}</span>
          </div>
        </div>
      </div>
      <el-pagination layout="prev, pager, next" background :page-size="5"
                     :total="showData.total" :current-page="showData.number"
                     @current-change="loadData">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import ZhuantiTitleBlock from "./ZhuantiTitleBlock";
import {jwczbtzc} from "../../api/zc_data";

export default {
  name: "CzzcBlock",
  components: {ZhuantiTitleBlock},
  data() {
    return {
      data: [],
      showData: {
        content: [],
        total: 0,
        number: 1
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.data = [...jwczbtzc].sort((a,b)=>b.time.localeCompare(a.time))
      this.showData.total = this.data.length
      this.loadData(1)
    },
    loadData(page){
      let cont = this.data;
      // 使用 slice() 来避免修改原始数据
      this.showData.content = cont.slice((page - 1) * 5, page * 5);
      this.showData.number = page;
      // 确保分页不会超出范围
      if (this.showData.content.length === 0 && page > 1) {
        this.loadData(page - 1); // 如果没有数据，尝试加载前一页
      }
    }
  }
}
</script>

<style scoped>
.title-list-container {
  padding: 0px 0px 0px 0px;
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.scrollable-list {
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
}
.title-item {
  padding: 8px 0px;
  border-bottom: 1px solid #E6E8F2;
  transition: box-shadow 0.2s ease;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.title-item:hover {
/*  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);*/
}
.title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 4px;
  padding-right: 50px;
}
.title-link {
  color: #0356AD;
  text-decoration: none;
}
.title-link:hover {
/*  text-decoration: underline;*/
}
.metadata {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #999;
}
.author{
  flex: 1;
  width: 100px;
}
.date{
  flex: 1;
  width: 100px;
}
.czzc-label-title{
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #0356AD;
  padding: 0px 0px 8px 0px;
}
.czzc-label-desc{
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #666666;
  /* 使元素支持多行截断 */
  display: -webkit-box;
  -webkit-line-clamp: 2;  /* 限制显示为2行 */
  -webkit-box-orient: vertical;
  overflow: hidden;  /* 隐藏超出部分 */
  text-overflow: ellipsis;  /* 使用...表示超出部分 */
}
</style>
