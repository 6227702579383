<template>
  <div class="zhuanti-div-container">
    <zhuanti-title-block :title="'境外展会'"></zhuanti-title-block>
    <div class="jwzh-content">
      <div class="left-scroll-button" @click="prev"></div>
      <el-carousel class="my-carousel" :autoplay="false" :loop="false" arrow="never" ref="carousel">
        <!-- 轮播项 -->
        <el-carousel-item v-for="(chunk, index) in chunkedReports" :key="index">
          <div class="icca-list">
            <div class="custom-row">
              <!-- 每列展示 2 行，每行 3 个卡片 -->
              <div class="custom-col" v-for="(item, colIndex) in chunk.slice(0, 6)" :key="item.id + colIndex"
                   :class="`col-bg-${colIndex}`">
                <div class="li_div">
                  <div class="expo-card">
                    <!-- 展会名称 -->
                    <div class="expo-title link" @click="openDetails(item)">
                      {{item.zhmc}}
                    </div>
                    <!-- 主办单位 -->
                    <div class="expo-info">
                      <span>主办单位：</span>
                      <span>{{item.zbdw}}</span>
                    </div>
                    <!-- 展览行业 -->
                    <div class="zl-industry">
                      <span>展览行业：</span>
                      <span>{{item.sshy}}</span>
                    </div>
                    <!-- 展会时间 -->
                    <div class="zh-date">
                      <span>{{item.jbkssj}} - {{item.jbjssj}}</span>
                    </div>
                    <!-- 展会地点 -->
                    <div class="expo-location">
                      <img src="../../assets/images/zhuanti/local-icon.png" alt="城市景观" class="local-image" />
                      {{item.country}}-{{item.province}}-{{item.jbcg}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="right-scroll-button" @click="next"></div>
    </div>
  </div>
</template>

<script>
import api from "../../api";
import ZhuantiTitleBlock from "./ZhuantiTitleBlock";

export default {
  name: "jwzhBlock",
  components: {ZhuantiTitleBlock},
  computed: {
    chunkedReports() {
      // 每次显示4个卡片
      const chunkSize = 6;
      const chunks = [];
      for (let i = 0; i < this.cardList.length; i += chunkSize) {
        chunks.push(this.cardList.slice(i, i + chunkSize));
      }
      return chunks;
    }
  },
  data(){
    return {
      data:null,
      searchParams:{
        sshy:null,
        province:null,
        state:null,
        startDate:null,
        endDate:null,
        zhmcLike:null,
        jbcgLike:null,
        zhmjGe:undefined,
        zhmjLe:undefined,
        zcbdwLike:null,
        zbdw:null,
        cbdw:null,
        abroad: true,
      },
      cardList: []
    }
  },
  created() {
    this.loadData(1)
  },
  methods:{
    prev() {
      this.$refs.carousel.prev();
    },
    next() {
      this.$refs.carousel.next();
    },
    async loadData(page){
      const params={
        ...this.searchParams,
        zbdws: ['米奥兰特','中国贸促会机械行业分会','贸促会纺织行业分会','中国贸促会'],
        type:'展会',
        page:page-1,
        size:18,
        sort:'jbkssj,DESC'
      }
      this.cardList=(await api.listZhanhui(params)).data.content
    },
    openDetails(item){
      this.$router.push({
        path: '/zhanhui/abroad-details',
        query:{id:item.id}
      });
    }
  }
}
</script>

<style scoped>
.jwzh-content{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.my-carousel{
  width: 100%;
}
.my-carousel ::v-deep .el-carousel__container{
  height: 390px !important;
}
/* 自定义行容器 */
.custom-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 每行 3 列 */
  gap: 24px; /* 每列之间的间隔 */
}
.col-bg-0,
.col-bg-3{
  border-radius: 16px;
  border: 1px solid #E6E8F2;
  background: linear-gradient(270deg, #FDF7F0 0%, #FFFDFB 100%);
  box-shadow: 4px 6px 8px 0px #EAEAEA;
}
.col-bg-1,
.col-bg-4{
  border-radius: 16px;
  border: 1px solid #E6E8F2;
  box-shadow: 4px 6px 8px 0px #EAEAEA;
  background: linear-gradient(270deg, #F2F9ED 0%, #FCFFFB 100%);
}
.col-bg-2,
.col-bg-5{
  border-radius: 16px;
  border: 1px solid #E6E8F2;
  box-shadow: 4px 6px 8px 0px #EAEAEA;
  background: linear-gradient(270deg, #EFF5FC 0%, #FBFCFF 100%);
}
/* 每个列的宽度自适应 */
.custom-col {
  display: flex;
  justify-content: center;
}
.icca-list{
  padding: 0px 12px;
}
.left-scroll-button{
  width: 16px;
  height: 16px;
  background-image: url("../../assets/images/zhuanti/left-icon.png");
  background-size: 100% 100%;
  cursor: pointer;
  flex: none;
}
.right-scroll-button{
  width: 16px;
  height: 16px;
  background-image: url("../../assets/images/zhuanti/right-icon.png");
  background-size: 100% 100%;
  cursor: pointer;
  flex: none;
}
</style>
