<template>
  <div class="zhuanti-div-container">
    <zhuanti-title-block :title="'企业出海'"></zhuanti-title-block>
    <div class="qych-container">
      <div class="quote-container">
        <img src="../../assets/images/zhuanti/dhao-icon.png">
        在 “不出海、就出局” 的时代语境下，无论是出于摆脱低价值内卷、寻求海外增量的考量，还是以全球化视野直接面向海外用户打造新品牌，中国企业出海已蔚然成风。</div>
      <div class="grid-container">
        <div class="cell mode-header" style="grid-column: span 4;">典型出海模式</div>
        <!-- 第一行：模式、典型特征、典型行业 -->
        <div class="cell features-header" style="grid-column: span 2;">模式</div>
        <div class="cell features-header">典型特征</div>
        <div class="cell features-header">典型行业</div>

        <!-- 第二行：第一行模式的区域合并，并填充内容 -->
        <div class="cell mode" style="grid-row: span 3;">国内增量有限/产能充裕,去海外寻找增量</div>
        <div class="cell competition" style="grid-row: span 1;">国内产能充裕、红海竞争,出海成为必选项</div>
        <div class="cell competition">规模经济特征+资本政策加码，叠加逆全球化趋势产业竞争加剧，部分行业产能过剩问题加剧</div>
        <div class="cell competition">新能源，如光伏</div>

        <!-- 第三行：继续模式的合并 -->
        <div class="cell industries" style="grid-row: span 1;">如家电等产业</div>
        <div class="cell industries">国内增量面临约束、竞争格局稳定,出海成为寻求增量主要来源;家电等出海较早,已有一定优势</div>
        <div class="cell industries">家电、工程机械</div>

        <!-- 第四行：继续模式的合并 -->
        <div class="cell competition" style="grid-row: span 1;">部分新兴产业国内渗透率有限</div>
        <div class="cell competition">国内渗透率仍有限，用户教育、相关基建或配套设施铺建均需时间;资本高注入希望能尽快寻求回报</div>
        <div class="cell competition">新能源车、机器人</div>

        <!-- 第四行：继续模式的合并 -->
        <div class="cell model1" style="grid-column: span 2;">供应链出海</div>
        <div class="cell industries">全球产业链重布局下,部分供应商海外建厂 也有为更积极快速响应链主需求主动出海建厂</div>
        <div class="cell industries">汽车零部件、电子产业链</div>
        <!-- 第四行：继续模式的合并 -->
        <div class="cell model2" style="grid-column: span 2;">商业模式出海</div>
        <div class="cell competition">立用端商业模式出海是我国优势所在,很多公司具备“全球化基因:电商平台出海加速也带来代工厂发展新机遇</div>
        <div class="cell competition">电商、游戏、社交软件</div>
      </div>
      <div class="zzqs-source">数据来源：中金公司</div>
      <div class="table-title">中国对外直接投资流量情况</div>
      <v-chart class="chart" :option="option"/>
      <div class="zzqs-source">数据来源：中国对外直接投资统计公报</div>
    </div>
  </div>
</template>

<script>
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {BarChart} from "echarts/charts";
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

import ZhuantiTitleBlock from "./ZhuantiTitleBlock";
export default {
  name: "QychBlock",
  components: {VChart,ZhuantiTitleBlock},
  data() {
    return {
      // 行组数据
      rowGroups: [
        // 第三到第五行的数据（4列结构）
        [
          "典型特征内容 A1", "典型特征内容 A2", "典型行业内容 A3"
        ],
        [
          "典型特征内容 B1", "典型特征内容 B2", "典型行业内容 B3"
        ],
        [
          "典型特征内容 C1", "典型特征内容 C2", "典型行业内容 C3"
        ],
        // 第六到第七行的数据（3列结构）
        [
          "供应链出海", "全球布局案例", "相关行业"
        ],
        [
          "商业模式出海", "全球视角创新", "相关行业"
        ]
      ]
    }
  },
  computed: {
    option() {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {
          show: false,
          data: ['金额'], // 数据图例
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          name: '年份',
          type: 'category',
          data: [
            2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015,
            2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023,
          ], // 横坐标年份数据
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}', // 格式化 Y 轴标签
          },
          name: '单位：亿美元'
        },
        series: [
          {
            name: '金额',
            data: [
              55, 122.6, 211.6, 265.1, 559.1, 565.3, 688.1, 746.5, 878, 1078.4,
              1231.2, 1456.7, 1961.5, 1582.9, 1430.4, 1369.1, 1537.1, 1788.2, 1631.2,
              1772.9,
            ], // 金额数据
            type: 'line', // 折线图
            smooth: true, // 平滑曲线
            lineStyle: {
              color: '#13ea5b', // 折线颜色
              width: 2, // 折线宽度
            },
            itemStyle: {
              color: '#13ea5b', // 节点颜色
            },
          },
        ],
      }
    }
  },
  methods: {
    // 动态获取单元格样式
    getCellStyle(cell) {
      const { rowStart, rowSpan = 1, colStart, colSpan = 1 } = cell;
      return {
        "grid-row": `${rowStart} / span ${rowSpan}`,
        "grid-column": `${colStart} / span ${colSpan}`
      };
    }
  }
}
</script>

<style scoped>
/* 全局容器 */
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 4fr 1fr; /* 调整列宽，前两列较窄，第三列较宽 */
  grid-template-rows: auto; /* 自动调整行高 */
  gap: 0px; /* 设置单元格间距 */
  padding: 0px 0px;
}

/* 样式化每个单元格 */
.cell {
  display: flex;
  align-items: center;   /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  padding: 15px;
  text-align: center;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}

/* 标题行样式 */
.mode-header {
  font-weight: bold;
  background-color: #0646A5;
  color: #FFFFFF;
  padding: 10px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;   /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}

/* 标题行样式 */
.features-header{
  font-weight: bold;
  background-color: #41CFF6;
  color: #FFFFFF;
  padding: 10px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;   /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}
.industries{
  background-color: #DEF2F4;
  font-size: 14px;
  border: 1px solid #ddd;
  padding: 10px;
  display: flex;
  align-items: center;   /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}

     /* 样式化模式内容 */
.mode {
  background-color: #A5E3F5;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #d6d6d6;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;   /* 垂直居中 */
  justify-content: flex-start; /* 水平左对齐 */
}
.model1{
  font-weight: bold;
  background-color: #C6EFFC;
  color: #0c0c0c;
  padding: 10px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;   /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}
.model2{
  font-weight: bold;
  background-color: #A5E3F5;
  color: #0c0c0c;
  padding: 10px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;   /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}

/* 样式化典型特征和行业内容 */
.competition{
  background-color: #AFE7EB;
  font-size: 14px;
  border: 1px solid #ddd;
  padding: 10px;
  display: flex;
  align-items: center;   /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}

/* 行合并后的效果 */
.cell[style*="grid-row: span 2;"] {
  background-color: #d3e3f2;
  font-weight: bold;
}

/* 确保当内容占多个列时的样式 */
/*.cell[style*="grid-column: span 2;"] {
  background-color: #d3e3f2;
  text-align: left;
  display: flex;
  align-items: center;   !* 垂直居中 *!
}*/
.table-title{
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #000000;
  padding: 16px 0px;
}
.chart {
  height: 300px;
}
.zzqs-source{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
  color: #979797;
}
</style>
