<template>
  <div class="title-container">
    <div><img src="../../assets/images/zhuanti/fc-icom.png" class="fc-image"></div>
    <div class="title-image-container">
      <div class="text-overlay">{{ title }}</div>
      <img src="../../assets/images/zhuanti/rectangle1.png" class="image">
      <img src="../../assets/images/zhuanti/rectangle2.png" class="image">
      <img src="../../assets/images/zhuanti/rectangle3.png" class="image">
    </div>
  </div>
</template>

<script>
export default {
  name: "ZhuantiTitleBlock",
  props: {
    title: String
  }
}
</script>

<style scoped>
.title-container{
  display: flex;
  margin-bottom: 24px;
}
.fc-image{
  width: 32px;
  margin-right: 5px;
}
.title-image-container {
  position: relative; /* 为了让子元素可以绝对定位 */
  width: 150px; /* 容器宽度 */
  height: 32px; /* 容器高度 */
}


.image {
  position: absolute; /* 绝对定位 */
  top: 0;
  left: 0;
  height: auto; /* 高度自适应，保持宽高比 */
  transition: transform 0.3s ease-in-out; /* 平滑过渡效果 */
}

.image:nth-child(1) {
  transform: scale(1); /* 最大的图片 */
  width: 120px;
  height: 32px;
}

.image:nth-child(2) {
  transform: scale(1); /* 中等大小的图片 */
  width: 134px;
  height: 32px;
}

.image:nth-child(3) {
  transform: scale(1); /* 最小的图片 */
  width: 150px;
  height: 32px;
}
/* 文字层叠效果 */
.text-overlay {
  position: absolute; /* 绝对定位文字 */
  top: 50%; /* 文字垂直居中 */
  left: 32%; /* 文字水平居中 */
  transform: translate(-50%, -50%); /* 使用 transform 使文字居中 */
  color: white; /* 白色字体 */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* 添加文字阴影使其更显眼 */
  z-index: 10; /* 设置文字层的 z-index，确保在图片层之上 */
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  text-decoration-skip-ink: none;

}
</style>
