<template>
  <div class="page-content" v-if="changguan">
    <div class="changguan-details-header">
      <div class="changguan-details-header-left">
        <div><img class="changguan-details-header-logo" v-attachment-attr:src="changguan.logo"></div>
      </div>
      <div class="changguan-details-header-right">
        <div class="changguan-details-title">
          {{changguan.mc}}
        </div>
        <el-row>
          <el-col :span="24">
            <div class="changguan-details-fields">
              <div class="info-field" style="width: 50%">
                <span class="info-field-label">英文名称：</span>
                <span class="info-field-value" :title="changguan.enmc">{{changguan.enmc}}</span>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="changguan-details-fields">
              <div class="info-field" style="width: 70%">
                <span class="info-field-label">展馆地址：</span>
                <span class="info-field-value" :title="changguan.dz">{{changguan.dz}}</span>
              </div>
              <div class="info-field" style="width: 30%">
                <span class="info-field-label">场馆面积：</span>
                <span class="info-field-value">{{changguan.mj}}㎡</span>
              </div>
              <div class="info-field">
                <span class="info-field-label">国家(地区)：</span>
                <span class="info-field-value">{{
                    (changguan.country ? changguan.country : '') +
                    (changguan.province ? (changguan.country ? '-' + changguan.province : changguan.province) : '') +
                    (changguan.city ? (changguan.country || changguan.province ? '-' + changguan.city : changguan.city) : '')
                  }}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="10" v-if="openingZhanhui&&openingZhanhui.length>0">
            <div class="opening-zhanhui-title">正在举办会展：</div>
            <div>
              <a v-for="item in openingZhanhui" :key="item.id" class="link opening-zhanhui-link"
                 :href="$router.resolve({path:'/zhanhui/details',query:{id:item.id}}).href">{{item.zhmc}}</a>
            </div>
          </el-col>
        </el-row>

      </div>
    </div>
    <el-row :gutter="30">
      <el-col :span="12">
        <block title="场馆介绍">
          <div v-html="richTextToHtml(changguan.jj)" style="height: 360px;overflow: auto;"></div>
        </block>
      </el-col>
      <el-col :span="12">
        <changguan-news-block :abroad="true"></changguan-news-block>
      </el-col>
    </el-row>
    <el-row :gutter="30">
      <el-col :span="24">
        <div class="company-list-scroll">
          <div class="left-scroll-button" @click="prev"></div>
          <el-carousel class="my-carousel"
                       :autoplay="false" :loop="false" arrow="never" ref="carousel">
            <el-carousel-item v-for="chunk in chunkedReports" :key="chunk[0]">
              <div class="icca-list">
                <div v-for="item in chunk" :key="item.id">
                  <div class="li_div">
                    <img :src="$api.getAttachmentUrl(item)">
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <div class="right-scroll-button" @click="next"></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AttachmentAttrDirective from "./AttachmentAttrDirective";
import api from "../api";
import HtmlUtils from "../utils/HtmlUtils";
import Block from "./Block";
import ChangguanNewsBlock from "./ChangguanNewsBlock";
export default {
  name: "AbroadChangguanDetails",
  components:{
    Block,
    ChangguanNewsBlock,
  },
  directives: {
    'attachment-attr': AttachmentAttrDirective
  },
  data() {
    return {
      changguan:null,
      openingZhanhui:[],
      pics: []
    }
  },
  created() {
    this.init()
  },
  computed: {
    chunkedReports() {
      const chunkSize = 3;
      const chunks = [];
      for (let i = 0; i < this.pics.length; i += chunkSize) {
        chunks.push(this.pics.slice(i, i + chunkSize));
      }
      return chunks;
    }
  },
  methods: {
    async init(){
      this.changguan=(await api.getChangguanById(this.$route.query.id)).data
      if(this.changguan.pics){
        this.pics= this.changguan.pics.split(',')
      }else{
        this.pics = []
      }
      this.openingZhanhui=(await api.listZhanhui({jbcg:this.changguan.mc,opening:true,page:0,size:10})).data.content.slice(0,4)
    },
    richTextToHtml(str){
      return HtmlUtils.richTextToHtml(str)
    },
    prev() {
      this.$refs.carousel.prev();
    },
    next() {
      this.$refs.carousel.next();
    },
  }
}
</script>

<style scoped>
.company-list-scroll{
  background: #FFFFFF;
}
.icca-list{
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  margin: 0 20px;
  flex-direction: row;
  align-content: center;
  align-items: center;
}
.li_div{
  width: 30%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.li_div img{
  width: 300px;
}
.my-carousel{
  width: 100%;
  padding: 10px 20px;
}
.my-carousel ::v-deep .el-carousel__container{
  height: 220px !important;
}
</style>
