<template>
  <div class="zhuanti-div-container">
    <zhuanti-title-block :title="'增长趋势'"></zhuanti-title-block>
    <div class="zhuanti-zzqs-container">
      <div class="quote-container">
        <img src="../../assets/images/zhuanti/dhao-icon.png">
        在经历了2020-2022受全球疫情影响的低谷后，2023年呈现出爆发式的增长。具体而言，2023年中国境外自主办展总数为<span style="color: red">190</span>个，相较于2022年的24个增加了<span style="color: red">166</span>个，增幅高达691.67%，展览总面积达到97.72万平方米，同比增加了76.81万平方米，增幅达367.34%。</div>
      <div class="table-title">中国境外自主办展分类数量比较</div>
      <v-chart class="chart" :option="option"/>
      <div class="table-title">中国主办方境外办展统计</div>
      <el-table
          :data="data"
          style="width: 100%;margin-bottom: 20px;"
          row-key="name"
          border
          :expand-row-keys="['按隶属关系分组','按注册登记类型分组','按行业分组']"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column
            prop="year"
            label="年份"
            width="80">
        </el-table-column>
        <el-table-column label="办展机构数量" align="center">
          <el-table-column
              prop="organizingInstitutions"
              label="个" align="right">
          </el-table-column>
          <el-table-column
              prop="growthRates_organizingInstitutions"
              label="比上年增长%" align="right">
          </el-table-column>
        </el-table-column>
        <el-table-column label="展览数量" align="center">
          <el-table-column
              prop="exhibitionCount"
              label="个" align="right">
          </el-table-column>
          <el-table-column
              prop="growthRates_exhibitionCount"
              label="比上年增长%" align="right">
          </el-table-column>
        </el-table-column>
        <el-table-column label="展览面积" align="center">
          <el-table-column
              prop="exhibitionArea"
              label="万平方米" align="right">
          </el-table-column>
          <el-table-column
              prop="growthRates_exhibitionArea"
              label="比上年增长%" align="right">
          </el-table-column>
        </el-table-column>
        <el-table-column label="展览平均面积" align="center">
          <el-table-column
              prop="averageExhibitionArea"
              label="万平方米" align="right">
          </el-table-column>
          <el-table-column
              prop="growthRates_averageExhibitionArea"
              label="比上年增长%" align="right">
          </el-table-column>
        </el-table-column>
      </el-table>
      <div class="zzqs-source">数据来源：2023中国展览数据统计报告</div>
    </div>
  </div>
</template>

<script>
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {BarChart} from "echarts/charts";
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);
import '../../echarts/map'
import api from "../../api";
import ZhuantiTitleBlock from "./ZhuantiTitleBlock";

export default {
  name: "zzqsBlock",
  components: {
    ZhuantiTitleBlock,
    VChart,
  },
  computed: {
    option() {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['合作办展', '独立办展'], // Legends for the two series
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.years, // Data for years (2016-2023)
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: '合作办展',
            data: [64, 50, 23, 41, 1, 5, 4, 68], // Your data for '合作办展'
            type: 'line', // Line chart
            smooth: true, // Smooth the line
            lineStyle: {
              color: '#c903c3', // Line color for '合作办展'border: 3px solid #5871C0
            },
          },
          {
            name: '独立办展',
            data: [19, 24, 32, 38, 1, 9, 20, 122], // Your data for '独立办展'
            type: 'line', // Line chart
            smooth: true, // Smooth the line
            lineStyle: {
              color: '#F3C96B', // Line color for '独立办展'border: 3px solid #F3C96B
            },
          }
        ]
      };
    }
  },
  data() {
    const months=[]
    for(let month=1;month<=12;month++){
      months.push(month+'月')
    }
    return {
      year:new Date().getFullYear()+'',
      data: [
        {
          "year": 2023,
          "organizingInstitutions": 37,
          "exhibitionCount": 190,
          "exhibitionArea": 97.72,
          "averageExhibitionArea": 0.51,
          "growthRates_organizingInstitutions": 516.67,
          "growthRates_exhibitionCount": 687.5,
          "growthRates_exhibitionArea": 368.64,
          "growthRates_averageExhibitionArea": -41.38
        },
        {
          "year": 2022,
          "organizingInstitutions": 6,
          "exhibitionCount": 24,
          "exhibitionArea": 20.91,
          "averageExhibitionArea": 0.87,
          "growthRates_organizingInstitutions": 20,
          "growthRates_exhibitionCount": 71.43,
          "growthRates_exhibitionArea": 770.42,
          "growthRates_averageExhibitionArea": 411.76
        },
        {
          "year": 2021,
          "organizingInstitutions": 5,
          "exhibitionCount": 14,
          "exhibitionArea": 2.4,
          "averageExhibitionArea": 0.17,
          "growthRates_organizingInstitutions": 150,
          "growthRates_exhibitionCount": 600,
          "growthRates_exhibitionArea": 42.86,
          "growthRates_averageExhibitionArea": -79.76
        },
        {
          "year": 2020,
          "organizingInstitutions": 2,
          "exhibitionCount": 2,
          "exhibitionArea": 1.68,
          "averageExhibitionArea": 0.84,
          "growthRates_organizingInstitutions": -93.1,
          "growthRates_exhibitionCount": -98.54,
          "growthRates_exhibitionArea": -97.54,
          "growthRates_averageExhibitionArea": 68
        },
        {
          "year": 2019,
          "organizingInstitutions": 29,
          "exhibitionCount": 137,
          "exhibitionArea": 67.92,
          "averageExhibitionArea": 0.5,
          "growthRates_organizingInstitutions": -14.71,
          "growthRates_exhibitionCount": 10.48,
          "growthRates_exhibitionArea": 2.59,
          "growthRates_averageExhibitionArea": 0
        },
        {
          "year": 2018,
          "organizingInstitutions": 34,
          "exhibitionCount": 124,
          "exhibitionArea": 66.2,
          "averageExhibitionArea": 0.5,
          "growthRates_organizingInstitutions": -5.56,
          "growthRates_exhibitionCount": 0.81,
          "growthRates_exhibitionArea": -20.86,
          "growthRates_averageExhibitionArea": -28.57
        },
        {
          "year": 2017,
          "organizingInstitutions": 36,
          "exhibitionCount": 123,
          "exhibitionArea": 83.6,
          "averageExhibitionArea": 0.7,
          "growthRates_organizingInstitutions": -2.7,
          "growthRates_exhibitionCount": -3.91,
          "growthRates_exhibitionArea": 7.21,
          "growthRates_averageExhibitionArea": 16.67
        },
        {
          "year": 2016,
          "organizingInstitutions": 37,
          "exhibitionCount": 128,
          "exhibitionArea": 78,
          "averageExhibitionArea": 0.6,
          "growthRates_organizingInstitutions": 60.87,
          "growthRates_exhibitionCount": 102.38,
          "growthRates_exhibitionArea": 142.23,
          "growthRates_averageExhibitionArea": 20
        },
        {
          "year": 2015,
          "organizingInstitutions": 23,
          "exhibitionCount": 63,
          "exhibitionArea": 32.2,
          "averageExhibitionArea": 0.5,
          "growthRates_organizingInstitutions": -36.11,
          "growthRates_exhibitionCount": -25,
          "growthRates_exhibitionArea": 20.37,
          "growthRates_averageExhibitionArea": 66.67
        },
        {
          "year": 2014,
          "organizingInstitutions": 36,
          "exhibitionCount": 84,
          "exhibitionArea": 26.8,
          "averageExhibitionArea": 0.3,
          "growthRates_organizingInstitutions": 0,
          "growthRates_exhibitionCount": 0,
          "growthRates_exhibitionArea": 0,
          "growthRates_averageExhibitionArea": 0
        }
      ],
      areas: ['华东','华南','西南','华北','西北','华中','东北'],
      months,
      years: ['2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'],
      data1: [
        {
          name: '合作办展',
          data: [64, 50, 23, 41, 1, 5, 4, 68] // Corresponding to 2016-2023
        },
        {
          name: '独立办展',
          data: [19, 24, 32, 38, 1, 9, 20, 122] // Corresponding to 2016-2023
        }
      ]
    }
  },
  created() {
    this.init()
  },
  watch:{
    year(){
      this.init()
    }
  },
  methods: {
    async init() {
      const data=(await api.getZhanhuiAreaMonthStat({year:this.year})).data
      const map={}
      data.forEach(i=>{
        map[i.area+i.month]=i.value
      })
      const areaDataList=[]
      for(const area of this.areas){
        const areaData={
          area,
          data:[]
        }
        for(let month=1;month<=12;month++){
          areaData.data.push(map[area+month]||0)
        }
        areaDataList.push(areaData)
      }
    },
  }
}
</script>

<style scoped>
.zzqs-source{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
  color: #979797;
}
.zhuanti-zzqs-container{
  padding: 0px 0px 0px 0px;
}
.chart {
  height: 300px;
}
.table-title{
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #000000;
  padding: 16px 0px;
}
</style>
