<template>
  <div class="page-content">
    <div class="page-tips">数据来源于E展网</div>
    <div class="filters" style="margin-top: 10px;" v-sticky>
      <div class="filters-left">市场总貌</div>
      <div class="filters-right">
        <el-select v-model="filterForm.area" clearable placeholder="全部区域" style="margin-left: 10px;width: 120px;"
                   size="small">
          <el-option v-for="item in areas" :key="item" :value="item" :label="item"></el-option>
        </el-select>
        <el-date-picker style="width: 250px;margin-left: 10px;" clearable
                        v-model="filterForm.dateRange"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期" size="small" unlink-panels
                        end-placeholder="结束日期" :picker-options="pickerOptions">
        </el-date-picker>
      </div>
    </div>
    <el-row>
      <el-col :span="24">
        <div class="indicators">
          <div class="indicator" v-for="item in indicators" :key="item.title">
            <div class="indicator-top">
              <span v-if="item.onclick" class="indicator-value indicator-value-link" @click="item.onclick">{{item.value}}</span>
              <span v-else class="indicator-value">{{item.value}}</span>
              <span class="indicator-unit">{{item.unit}}</span>
            </div>
            <div class="indicator-bottom">
              <img :src="item.icon" class="indicator-icon">
              <span class="indicator-title">{{item.title}}</span>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="30">
      <el-col :span="12">
        <zhanhui-list-block :params="filterParams"></zhanhui-list-block>
      </el-col>
      <el-col :span="12">
        <zhanhui-news-block></zhanhui-news-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <zhanhui-area-year-stat-block></zhanhui-area-year-stat-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <zhanhui-area-stat-block :params="filterParams"></zhanhui-area-stat-block>
      </el-col>
    </el-row>
    <el-row :gutter="30">
      <el-col :span="8">
        <zhanhui-mj-stat-block :params="filterParams"></zhanhui-mj-stat-block>
      </el-col>
      <el-col :span="8">
        <zhanhui-sshy-stat-block :params="filterParams"></zhanhui-sshy-stat-block>
      </el-col>
      <el-col :span="8">
        <zhanhui-jbzq-stat-block :params="filterParams"></zhanhui-jbzq-stat-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <zhanhui-sshy-stat-table-block :params="filterParams"></zhanhui-sshy-stat-table-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <zhanhui-year-stat-block title="历年新增展会" :params="filterParams"></zhanhui-year-stat-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <block title="UFI认证会展">
          <el-row>
            <el-col style="width: 15%">
              <el-image :src="require('../assets/images/ufi_logo.png')" lazy fit="fill"></el-image>
            </el-col>
            <el-col style="width: 85%">
              <ufi-certified></ufi-certified>
            </el-col>
          </el-row>
        </block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <zhanhui-rank-block :params="filterParams"></zhanhui-rank-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <zhanhui-org-block></zhanhui-org-block>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ZhanhuiListBlock from "./ZhanhuiListBlock";
import ZhanhuiAreaStatBlock from "./ZhanhuiAreaStatBlock";
import ZhanhuiNewsBlock from "./ZhanhuiNewsBlock";
import ZhanhuiMjStatBlock from "./ZhanhuiMjStatBlock";
import ZhanhuiSshyStatBlock from "./ZhanhuiSshyStatBlock";
import ZhanhuiJbzqStatBlock from "./ZhanhuiJbzqStatBlock";
import hyhz from '../assets/images/hyhz.png'
import pqgl from '../assets/images/pqgl.png'
import ywc from '../assets/images/ywc.png'
import dkmj from '../assets/images/dkmj.png'
import fz from '../assets/images/fz.png'
import sjfw from '../assets/images/sjfw.png'
import jlr from '../assets/images/jlr.png'
import Block from "./Block";
import api from "../api"
import ZhanhuiAreaYearStatBlock from "./ZhanhuiAreaYearStatBlock";
import moment from "moment";
import ZhanhuiSshyStatTableBlock from "./ZhanhuiSshyStatTableBlock";
import ZhanhuiYearStatBlock from "./ZhanhuiYearStatBlock";
import ZhanhuiRankBlock from "./ZhanhuiRankBlock";
import ZhanhuiOrgBlock from "./ZhanhuiOrgBlock";
import UfiCertified from "./UfiCertified";

export default {
  name: "ZhanhuiHome",
  components: {
    UfiCertified,
    Block,
    ZhanhuiAreaStatBlock,
    ZhanhuiListBlock,
    ZhanhuiNewsBlock,
    ZhanhuiMjStatBlock,
    ZhanhuiSshyStatBlock,
    ZhanhuiJbzqStatBlock,
    ZhanhuiAreaYearStatBlock,
    ZhanhuiSshyStatTableBlock,
    ZhanhuiYearStatBlock,
    ZhanhuiRankBlock,
    ZhanhuiOrgBlock
  },
  data(){
    const filterForm = {
      area: null,
      dateRange: [moment().subtract(4, "year").startOf('year').toDate(),
        moment().endOf('year').toDate()],
    }
    const filterParams = {
      area: null,
      startDate: null,
      endDate: null,
      type:'展会',
      abroad: false,
    }
    this.copyFilterParams(filterForm, filterParams)
    return {
      filterForm,
      filterParams,
      areas: ['华东', '华南', '西南', '华北', '西北', '华中', '东北'],
      indicators:[],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近3年',
            onClick(picker) {
              picker.$emit('pick', [moment().subtract(2, "years").startOf('year'),
                moment().endOf('year').toDate()]);
            }
          },
          {
            text: '最近5年',
            onClick(picker) {
              picker.$emit('pick', [moment().subtract(4, "years").startOf('year'),
                moment().endOf('year').toDate()]);
            }
          },
          {
            text: '最近10年',
            onClick(picker) {
              picker.$emit('pick', [moment().subtract(9, "years").startOf('year'),
                moment().endOf('year').toDate()]);
            }
          }]

      },
    }
  },
  watch: {
    filterForm: {
      deep: true,
      handler() {
        this.reloadData()
      }
    }
  },
  created() {
    this.init()
  },
  methods:{
    async init(){
      await this.loadData()
    },
    copyFilterParams(form, params) {
      params.area = form.area
      if (form.dateRange) {
        params.startDate = moment(form.dateRange[0]).format('YYYY-MM-DD')
        params.endDate = moment(form.dateRange[1]).format('YYYY-MM-DD')
      } else {
        params.startDate = null
        params.endDate = null
      }
    },
    async reloadData() {
      this.copyFilterParams(this.filterForm, this.filterParams)
      await this.loadData()
    },
    async loadData() {
      const data = (await api.getZhanhuiTotalStat(this.filterParams)).data
      this.indicators = [
        {
          title: '所有会展',
          value: data.total,
          unit: '场',
          icon: hyhz,
          onclick:()=>{
            this.$router.push({path:'/zhanhui/list',query:{...this.filterParams}})
          }
        },
        {
          title: '排期中',
          value: data.pending,
          unit: '场',
          icon: pqgl,
          onclick:()=>{
            this.$router.push({path:'/zhanhui/list',query:{state:'排期中',...this.filterParams}})
          }
        },
        {
          title: '已结束',
          value: data.finished,
          unit: '场',
          icon: ywc,
          onclick:()=>{
            this.$router.push({path:'/zhanhui/list',query:{state:'已结束',...this.filterParams}})
          }
        },
        {
          title: '总面积',
          value: (data.totalMj / 10000).toFixed(0),
          unit: '万平方米',
          icon: dkmj,
        },
        {
          title: '主办单位',
          value: data.totalZb,
          unit: '家',
          icon: fz
        },
        {
          title: '承办单位',
          value: data.totalCb,
          unit: '家',
          icon: sjfw
        },
        {
          title: '举办场馆',
          value: data.totalCg,
          unit: '家',
          icon: jlr
        }
        /*{
          title: '参展商',
          value: data.totalZssl,
          unit: '家',
          icon: sjfw
        },
        {
          title: '参加观众',
          value: '--',
          unit: '万人',
          icon: jlr
        }*/
      ]
    }
  }
}
</script>

<style scoped>

</style>
