<template>
  <div class="page-content" v-if="data">
    <div class="page-tips">数据来源于海外会展网、世展网等</div>
    <div class="search-form" style="margin-top: 10px;">
      <!-- 地区选择 -->
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label" style="line-height: 30px;padding: 3px 0px;" >场馆地区：</div>
          <div class="search-form-field-content v-center">
            <div
                class="tag-select-item"
                v-for="region in regions"
                :key="region.code"
                :class="{'selected': selectedRegion === region.code}"
                @click="handleRegionChange(region.code)">
              {{ region.name }}
            </div>
          </div>
        </div>
      </div>
      <!-- 国家选择 -->
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label" style="line-height: 30px;padding: 3px 0px;">场馆国家：</div>
          <div class="search-form-field-content v-center">
            <div
                class="tag-select-item"
                v-for="country in filteredCountries"
                :key="country.code"
                :class="{'selected': selectedCountry === country.code}"
                @click="handleCountryChange(country.code)">
              {{ country.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">场馆：</div>
          <div class="search-form-field-content v-center">
            <el-input v-model="searchForm.mcLike" style="width: 200px;" size="mini"></el-input>
          </div>
        </div>
        <div class="search-form-field">
          <div class="search-form-field-label">面积：</div>
          <div class="search-form-field-content v-center">
            <el-input-number v-model="searchForm.minMj" style="width: 100px;" size="mini" :controls="false"></el-input-number>
            <span>~</span>
            <el-input-number v-model="searchForm.maxMj" style="width: 100px;" size="mini" :controls="false"></el-input-number>
            <span>㎡</span>
          </div>
        </div>
      </div>
      <div class="search-form-bottom">
        <el-button type="primary" @click="search">检索</el-button>
        <el-button @click="reset">重置</el-button>
      </div>
    </div>
    <div style="background-color: #FFFFFF;padding: 20px;margin-top: 20px;">
      <el-table :data="data.content" stripe class="large-font-table" tooltip-effect="light">
        <el-table-column prop="mc" label="中文名称" width="280" show-overflow-tooltip>
          <a class="link" slot-scope="{row}" :href="$router.resolve({path:'/changguan/abroadDetails',query:{id:row.id}}).href">{{row.mc}}</a>
        </el-table-column>
        <el-table-column prop="enmc" label="英文名称" width="320" show-overflow-tooltip>
          <a class="link" slot-scope="{row}" :href="$router.resolve({path:'/changguan/abroadDetails',query:{id:row.id}}).href">{{row.enmc}}</a>
        </el-table-column>
        <el-table-column prop="country" label="国家(地区)" width="180" show-overflow-tooltip>
          <div slot-scope="{row}">
            {{
              (row.country ? row.country : '') +
              (row.province ? (row.country ? '-' + row.province : row.province) : '') +
              (row.city ? (row.country || row.province ? '-' + row.city : row.city) : '')
            }}
          </div>
        </el-table-column>
        <el-table-column prop="mj" label="面积（㎡）" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="dz" label="场馆地址" show-overflow-tooltip></el-table-column>
      </el-table>
      <el-pagination layout="prev, pager, next" background
                     :total="data.totalElements" :current-page="data.number+1"
                     @current-change="loadData">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from "../api";
import moment from "moment";
const DATE_FMT='YYYY-MM-DD';

export default {
  name: "AbroadChangguanList",
  components:{},
  data(){
    return {
      industries:["机械工业","电子电力","光电技术","交通工具","运输物流","汽摩配件","仪器仪表","暖通制冷","信息通信","安全防护","建材五金","能源矿产","钢铁冶金","纺织纺机","印刷包装","化工橡塑","环保水处理","食品饮料","服装配饰","皮革鞋业","美容美发","房产家居","孕婴童展","旅游行业","消费电子","婚庆婚博","音响乐器","礼品玩具","医疗保健","体育休闲","办公用品","纸业制品","酒店用品","奢侈品展","宠物行业","动漫游戏","购物年货","进出口展","创业加盟","广告媒体","文化教育","文化艺术","农林牧渔",'其它'],
      searchForm:{
        hy:[],
        usedState:null,
        dateRange:null,
        mcLike:null,
        zhmcLike:null,
        type:null,
        minMj:undefined,
        maxMj:undefined
      },
      searchParams:{
        hy:null,
        province:null,
        usedState:null,
        usedFrom:null,
        usedEnd:null,
        mcLike:null,
        zhmcLike:null,
        type:null,
        minMj:null,
        maxMj:null,
        abroad: true,
      },
      data:null,
      // 当前选中的地区和国家编码
      selectedRegion: '0', // 默认地区代码（如：2代表欧洲）
      selectedCountry: '178', // 默认国家代码
      // 地区列表
      regions: [
        { code: '0', name: '全部' },
        { code: '2', name: '欧洲' },
        { code: '3', name: '亚洲' },
        { code: '4', name: '北美洲' },
        { code: '5', name: '大洋洲' },
        { code: '6', name: '非洲' },
        { code: '7', name: '南美洲' },
      ],
      countriesData: {
        '0': [ // 全部
          { code: '178', name: '全部' },
          { code: '1', name: '德国' },
          { code: '2', name: '法国' },
          { code: '3', name: '意大利' },
          { code: '4', name: '俄罗斯' },
          { code: '5', name: '英国' },
          { code: '6', name: '西班牙' },
          { code: '12', name: '日本' },
          { code: '13', name: '韩国' },
          { code: '14', name: '印度' },
          { code: '21', name: '加拿大' },
          { code: '22', name: '墨西哥' },
          { code: '23', name: '美国' },
          { code: '24', name: '巴拿马' },
          { code: '25', name: '牙买加' },
          { code: '26', name: '阿鲁巴' },
          { code: '27', name: '哥斯达黎加' },
          { code: '28', name: '澳大利亚' },
          { code: '29', name: '新西兰' },
          { code: '30', name: '马耳他' },
          { code: '31', name: '黑山' },
          { code: '32', name: '摩纳哥' },
          { code: '33', name: '格鲁吉亚' },
          { code: '34', name: '马其顿' },
          { code: '35', name: '阿塞拜疆' },
          { code: '36', name: '摩尔多瓦' },
          { code: '37', name: '爱沙尼亚' },
          { code: '38', name: '荷兰' },
          { code: '39', name: '瑞士' },
          { code: '40', name: '捷克' },
          { code: '41', name: '希腊' },
          { code: '42', name: '奥地利' },
          { code: '43', name: '土耳其' },
          { code: '44', name: '波兰' },
          { code: '45', name: '丹麦' },
          { code: '46', name: '瑞典' },
          { code: '47', name: '葡萄牙' },
          { code: '48', name: '卢森堡' },
          { code: '49', name: '爱尔兰' },
          { code: '50', name: '斯洛文尼亚' },
          { code: '51', name: '芬兰' },
          { code: '52', name: '白俄罗斯' },
          { code: '53', name: '匈牙利' },
          { code: '54', name: '挪威' },
          { code: '55', name: '波黑' },
          { code: '56', name: '克罗地亚' },
          { code: '57', name: '斯洛伐克' },
          { code: '58', name: '立陶宛' },
          { code: '59', name: '保加利亚' },
          { code: '60', name: '乌克兰' },
          { code: '61', name: '罗马尼亚' },
          { code: '62', name: '比利时' },
          { code: '63', name: '埃及' },
          { code: '64', name: '博茨瓦纳' },
          { code: '65', name: '喀麦隆' },
          { code: '66', name: '赞比亚' },
          { code: '67', name: '肯尼亚' },
          { code: '68', name: '坦桑尼亚' },
          { code: '69', name: '尼日利亚' },
          { code: '70', name: '南非' },
          { code: '71', name: '阿尔及利亚' },
          { code: '72', name: '埃塞俄比亚' },
          { code: '73', name: '巴西' },
          { code: '74', name: '阿根廷' },
          { code: '75', name: '智利' },
          { code: '76', name: '哥伦比亚' },
          { code: '77', name: '秘鲁' },
          { code: '78', name: '越南' },
          { code: '79', name: '阿联酋' },
          { code: '80', name: '新加坡' },
          { code: '81', name: '印尼' },
          { code: '82', name: '马来西亚' },
          { code: '83', name: '泰国' },
          { code: '84', name: '菲律宾' },
          { code: '85', name: '孟加拉' },
          { code: '86', name: '斯里兰卡' },
          { code: '87', name: '以色列' },
          { code: '88', name: '哈萨克斯坦' },
          { code: '89', name: '乌兹别克斯坦' },
          { code: '90', name: '土库曼斯坦' },
          { code: '91', name: '巴基斯坦' },
          { code: '92', name: '沙特阿拉伯' },
          { code: '93', name: '卡塔尔' },
          { code: '94', name: '阿曼' },
          { code: '95', name: '蒙古' },
          { code: '177', name: '伊朗' },
          { code: '97', name: '尼泊尔' },
          { code: '98', name: '约旦' },
          { code: '99', name: '伊拉克' },
          { code: '181', name: '厄瓜多尔' },
          { code: '182', name: '玻利维亚' },
          { code: '183', name: '巴拉圭' },
          { code: '184', name: '苏里南' },
          { code: '185', name: '津巴布韦' },
          { code: '186', name: '突尼斯' },
          { code: '187', name: '加纳' },
          { code: '188', name: '安哥拉' },
          { code: '189', name: '卢旺达' },
          { code: '190', name: '苏丹' },
          { code: '191', name: '摩洛哥' },
          { code: '192', name: '马达加斯加' },
          { code: '193', name: '塞内加尔' },
          { code: '194', name: '波多黎各' },
          { code: '195', name: '古巴' },
        ],
        '2': [ // 欧洲
          { code: '178', name: '全部' },
          { code: '1', name: '德国' },
          { code: '2', name: '法国' },
          { code: '3', name: '意大利' },
          { code: '4', name: '俄罗斯' },
          { code: '5', name: '英国' },
          { code: '6', name: '西班牙' },
          { code: '30', name: '马耳他' },
          { code: '31', name: '黑山' },
          { code: '32', name: '摩纳哥' },
          { code: '33', name: '格鲁吉亚' },
          { code: '34', name: '马其顿' },
          { code: '35', name: '阿塞拜疆' },
          { code: '36', name: '摩尔多瓦' },
          { code: '37', name: '爱沙尼亚' },
          { code: '38', name: '荷兰' },
          { code: '39', name: '瑞士' },
          { code: '40', name: '捷克' },
          { code: '41', name: '希腊' },
          { code: '42', name: '奥地利' },
          { code: '43', name: '土耳其' },
          { code: '44', name: '波兰' },
          { code: '45', name: '丹麦' },
          { code: '46', name: '瑞典' },
          { code: '47', name: '葡萄牙' },
          { code: '48', name: '卢森堡' },
          { code: '49', name: '爱尔兰' },
          { code: '50', name: '斯洛文尼亚' },
          { code: '51', name: '芬兰' },
          { code: '52', name: '白俄罗斯' },
          { code: '53', name: '匈牙利' },
          { code: '54', name: '挪威' },
          { code: '55', name: '波黑' },
          { code: '56', name: '克罗地亚' },
          { code: '57', name: '斯洛伐克' },
          { code: '58', name: '立陶宛' },
          { code: '59', name: '保加利亚' },
          { code: '60', name: '乌克兰' },
          { code: '61', name: '罗马尼亚' },
          { code: '62', name: '比利时' },
        ],
        '3': [ // 亚洲
          { code: '178', name: '全部' },
          { code: '12', name: '日本' },
          { code: '13', name: '韩国' },
          { code: '14', name: '印度' },
          { code: '78', name: '越南' },
          { code: '79', name: '阿联酋' },
          { code: '80', name: '新加坡' },
          { code: '81', name: '印尼' },
          { code: '82', name: '马来西亚' },
          { code: '83', name: '泰国' },
          { code: '84', name: '菲律宾' },
          { code: '85', name: '孟加拉' },
          { code: '86', name: '斯里兰卡' },
          { code: '87', name: '以色列' },
          { code: '88', name: '哈萨克斯坦' },
          { code: '89', name: '乌兹别克斯坦' },
          { code: '90', name: '土库曼斯坦' },
          { code: '91', name: '巴基斯坦' },
          { code: '92', name: '沙特阿拉伯' },
          { code: '93', name: '卡塔尔' },
          { code: '94', name: '阿曼' },
          { code: '95', name: '蒙古' },
          { code: '177', name: '伊朗' },
          { code: '97', name: '尼泊尔' },
          { code: '98', name: '约旦' },
          { code: '99', name: '伊拉克' },
        ],
        '4': [ // 北美洲
          { code: '178', name: '全部' },
          { code: '21', name: '加拿大' },
          { code: '22', name: '墨西哥' },
          { code: '23', name: '美国' },
          { code: '24', name: '巴拿马' },
          { code: '25', name: '牙买加' },
          { code: '26', name: '阿鲁巴' },
          { code: '27', name: '哥斯达黎加' },
          { code: '194', name: '波多黎各' },
          { code: '195', name: '古巴' },
        ],
        '5': [ // 大洋洲
          { code: '178', name: '全部' },
          { code: '28', name: '澳大利亚' },
          { code: '29', name: '新西兰' }
        ],
        '6': [ // 非洲
          { code: '178', name: '全部' },
          { code: '63', name: '埃及' },
          { code: '64', name: '博茨瓦纳' },
          { code: '65', name: '喀麦隆' },
          { code: '66', name: '赞比亚' },
          { code: '67', name: '肯尼亚' },
          { code: '68', name: '坦桑尼亚' },
          { code: '69', name: '尼日利亚' },
          { code: '70', name: '南非' },
          { code: '71', name: '阿尔及利亚' },
          { code: '72', name: '埃塞俄比亚' },
          { code: '185', name: '津巴布韦' },
          { code: '186', name: '突尼斯' },
          { code: '187', name: '加纳' },
          { code: '188', name: '安哥拉' },
          { code: '189', name: '卢旺达' },
          { code: '190', name: '苏丹' },
          { code: '191', name: '摩洛哥' },
          { code: '192', name: '马达加斯加' },
          { code: '193', name: '塞内加尔' },
        ],
        '7': [ // 南美洲
          { code: '178', name: '全部' },
          { code: '73', name: '巴西' },
          { code: '74', name: '阿根廷' },
          { code: '75', name: '智利' },
          { code: '76', name: '哥伦比亚' },
          { code: '77', name: '秘鲁' },
          { code: '181', name: '厄瓜多尔' },
          { code: '182', name: '玻利维亚' },
          { code: '183', name: '巴拉圭' },
          { code: '184', name: '苏里南' },
        ],
      }
    }
  },
  computed: {
    // 根据当前选中的地区动态筛选国家列表
    filteredCountries() {
      return this.countriesData[this.selectedRegion] || [];
    }
  },
  created() {
    this.init()
  },
  methods:{
    // 处理地区选择变化
    handleRegionChange(regionCode) {
      this.selectedRegion = regionCode;
      // 选中地区后，重置国家选择为 "全部"
      this.selectedCountry = '178';
    },
    // 处理国家选择变化
    handleCountryChange(countryCode) {
      this.selectedCountry = countryCode;
    },
    async init(){
      if(this.$route.query.type){
        this.searchForm.type=this.$route.query.type
      }
      if(this.$route.query.usedState){
        this.searchForm.usedState=this.$route.query.usedState
        if(this.$route.query.startDate&&this.$route.query.endDate){
          this.searchForm.dateRange=[
            moment(this.$route.query.startDate,DATE_FMT),
            moment(this.$route.query.endDate,DATE_FMT)
          ]
        }
      }
      if(this.$route.query.hy){
        if(Array.isArray(this.$route.query.hy)){
          this.searchForm.hy=this.$route.query.hy
        }else{
          this.searchForm.hy=[this.$route.query.hy]
        }
      }
      await this.search()
    },
    async search(){
      this.searchParams.hy=this.searchForm.hy
      if(this.searchForm.usedState){
        this.searchParams.usedState=this.searchForm.usedState==='已有展会'
        if (this.searchForm.dateRange) {
          this.searchParams.usedFrom = moment(this.searchForm.dateRange[0]).format(DATE_FMT)
          this.searchParams.usedEnd = moment(this.searchForm.dateRange[1]).format(DATE_FMT)
        }else{
          this.searchParams.usedFrom = null
          this.searchParams.usedEnd = null
        }
      }else{
        this.searchParams.usedState=null
        this.searchParams.usedFrom = null
        this.searchParams.usedEnd = null
      }
      this.searchParams.type=this.searchForm.type
      this.searchParams.mcLike=this.searchForm.mcLike
      this.searchParams.zhmcLike=this.searchForm.zhmcLike
      this.searchParams.minMj=this.searchForm.minMj
      this.searchParams.maxMj=this.searchForm.maxMj
      this.searchParams.country = this.selectedCountry
      this.searchParams.area = this.selectedRegion
      await this.loadData(1)
    },
    async reset(){
      this.searchForm.hy=[]
      this.searchForm.usedState=null
      this.searchForm.dateRange=null
      this.searchForm.mcLike=null
      this.searchForm.zhmcLike=null
      this.searchForm.type=null
      this.searchForm.minMj=undefined
      this.searchForm.maxMj=undefined
    },
    async loadData(page){
      const params={
        ...this.searchParams,
        page:page-1,
        size:10,
      }
      this.data=(await api.listChangguan(params)).data
    }
  }
}
</script>

<style scoped>
/* 自定义样式 */
span {
  font-weight: bold;
  margin-right: 10px;
}
.el-radio-group {
  margin-right: 20px;
}
/* 自定义样式 */
span {
  font-weight: bold;
  margin-right: 10px;
}



</style>
