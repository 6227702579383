<template>
  <div class="zhuanti-div-container">
  <zhuanti-title-block :title="'地域分析'"></zhuanti-title-block>
  <div class="map-div-container">
    <div class="quote-container">
      <img src="../../assets/images/zhuanti/dhao-icon.png">
      2023 年，在中国境外自主办展的 <span style="color: red">190</span> 个展览中，有 <span style="color: red">109</span> 个在“一带一路”沿线国家举办，占 57.37%，同比 2022 年的 <span style="color: red">13</span> 个增长了 <span style="color: red">96</span> 个，增幅 738.46%;展览总面积 55.75 万平方米，占中国境外自主办展展览总面积的 57.05%，同比 2022 年的 11.58 万平方米增加 44.17万平方米。</div>
      <div class="data-icon-div">
        <img class="data-icon-img" @click="showTable" src="../../assets/images/zhuanti/data-icon.png">
      </div>
      <div v-if="map" class="qq-map-div">
        <!-- 红点列表 -->
        <div
            v-for="(point, index) in points"
            :key="index"
            class="red-dot"
            :style="{ top: point.y + 'px', left: point.x + 'px' }"
            @mouseenter="showTooltip(index)"
            @mouseleave="hideTooltip"
        >
          <!-- 显示轮播的 Tooltip -->
          <div v-if="activeTooltip !== null"
              v-show="activeTooltip === index"
              class="tooltip">
            <div class="tooltip-content">
              <span class="country-label">{{ point.country }}</span><br>
              <span class="yearbz-label" v-if="point.exhibitions2022 && point.area2022">2022办展<br></span>
              <span class="text-label" v-if="point.exhibitions2022 && point.area2022">{{ point.exhibitions2022 || '' }}个，{{ point.area2022 || '' }}万m2<br></span>
              <span class="yearbz-label" v-if="point.exhibitions2023 && point.area2023">2023办展<br></span>
              <span class="text-label" v-if="point.exhibitions2023 && point.area2023">{{ point.exhibitions2023 || '' }}个
                {{(point.radio && point.radio>0)?('(↑'+(point.radio*100).toFixed(2)+'%)'):(point.radio && !(point.radio>0) && point.radio!=0?('(↓'+(point.radio*100).toFixed(2)+'%)'):'')}}<br>
                {{ point.area2023 || '' }}万m2
                {{(point.radio1 && point.radio>0)?('(↑'+(point.radio1*100).toFixed(2)+'%)'):((point.radio1 && !(point.radio1>0) && point.radio1!=0)?('(↓'+(point.radio1*100).toFixed(2)+'%)'):'')}}</span>
            </div>
          </div>
        </div>
      </div>
      <el-table v-else :data="points" max-height="300">
        <el-table-column label="国家" prop="country"></el-table-column>
        <el-table-column label="办展数量 (2022)" prop="exhibitions2022"></el-table-column>
        <el-table-column label="办展数量 (2023)" prop="exhibitions2023"></el-table-column>
        <el-table-column label="办展面积 (2022, 万平方米)" prop="area2022"></el-table-column>
        <el-table-column label="办展面积 (2023, 万平方米)" prop="area2023"></el-table-column>
      </el-table>
    <div class="qq-map-source">数据来源：2023中国展览数据统计报告</div>
  </div>
  </div>
</template>

<script>
import ZhuantiTitleBlock from "./ZhuantiTitleBlock";
export default {
  name: "ZhuantiMapBlock",
  components: {ZhuantiTitleBlock},
  data() {
    return {
      map: true,
      points: [
        {
          "country": "印度尼西亚",
          "exhibitions2022": 2,
          "exhibitions2023": 27,
          "area2022": 3.96,
          "area2023": 16.17,
          "x": 922,
          "y": 322,
          "radio": 12.5,
          "radio1": 3.083,
        },
        {
          "country": "巴西",
          "exhibitions2022": 2,
          "exhibitions2023": 18,
          "area2022": 1.48,
          "area2023": 4.66,
          "x": 434,
          "y": 349,
          "radio": 8,
          "radio1": 2.148648649,
        },
        {
          "country": "墨西哥",
          "exhibitions2022": 2,
          "exhibitions2023": 17,
          "area2022": 2.8,
          "area2023": 2.43,
          "x": 217,
          "y": 249,
          "radio": 7.5,
          "radio1": -0.132142857,
        },
        {
          "country": "越南",
          "exhibitions2022": 1,
          "exhibitions2023": 15,
          "area2022": 0.55,
          "area2023": 4.67,
          "x": 919,
          "y": 271,
          "radio": 14,
          "radio1":	7.490909091,
        },
        {
          "country": "阿联酋",
          "exhibitions2022": 2,
          "exhibitions2023": 14,
          "area2022": 3.07,
          "area2023": 10.4,
          "x": 757,
          "y": 264,
          "radio": 6,
          "radio1":	2.38762215,
        },
        {
          "country": "日本",
          "exhibitions2022": 4,
          "exhibitions2023": 11,
          "area2022": 3.11,
          "area2023": 8.92,
          "x": 1047,
          "y": 218,
          "radio": 1.75,
          "radio1":	1.868167203,
        },
        {
          "country": "南非",
          "exhibitions2022": 2,
          "exhibitions2023": 9,
          "area2022": 1.38,
          "area2023": 3,
          "x": 653,
          "y": 409,
          "radio": 3.5,
          "radio1":	1.173913043,
        },
        {
          "country": "土耳其",
          "exhibitions2022": 1,
          "exhibitions2023": 9,
          "area2022": 0.58,
          "area2023": 1.13,
          "x": 656,
          "y": 155,
          "radio": 8,
          "radio1":	0.948275862,
        },
        {
          "country": "印度",
          "exhibitions2022": 2,
          "exhibitions2023": 2,
          "area2022": 1.19,
          "area2023": 4,
          "x": 840,
          "y": 248,
          "radio": 0,
          "radio1":	2.361344538,
        },
        {
          "country": "肯尼亚",
          "exhibitions2022": 1,
          "exhibitions2023": 2,
          "area2022": 0.3,
          "area2023": 0.5,
          "x": 703,
          "y": 320,
          "radio": 1,
          "radio1":	0.666666667,
        },
        {
          "country": "巴基斯坦",
          "exhibitions2022": 1,
          "exhibitions2023": 2,
          "area2022": 0.4,
          "area2023": 1.62,
          "x": 751,
          "y": 207,
          "radio": 1,
          "radio1":	3.05,
        },
        {
          "country": "埃及",
          "exhibitions2022": 1,
          "exhibitions2023": null,
          "area2022": 0.7,
          "area2023": null,
          "x": 685,
          "y": 257
        },
        {
          "country": "俄罗斯",
          "exhibitions2022": null,
          "exhibitions2023": 5,
          "area2022": null,
          "area2023": 3.22,
          "x": 758,
          "y": 143
        },
        {
          "country": "尼日利亚",
          "exhibitions2022": null,
          "exhibitions2023": 3,
          "area2022": null,
          "area2023": 1.22,
          "x": 535,
          "y": 289
        },
        {
          "country": "葡萄牙",
          "exhibitions2022": null,
          "exhibitions2023": 2,
          "area2022": null,
          "area2023": 0.1,
          "x": 560,
          "y": 203
        },
        {
          "country": "匈牙利",
          "exhibitions2022": null,
          "exhibitions2023": 2,
          "area2022": null,
          "area2023": 1.8,
          "x": 615,
          "y": 128
        },
        {
          "country": "蒙古",
          "exhibitions2022": null,
          "exhibitions2023": 2,
          "area2022": null,
          "area2023": 1.2,
          "x": 900,
          "y": 154
        },
        {
          "country": "哈萨克斯坦",
          "exhibitions2022": null,
          "exhibitions2023": 2,
          "area2022": null,
          "area2023": 1.2,
          "x": 702,
          "y": 146
        },
        {
          "country": "哥伦比亚",
          "exhibitions2022": null,
          "exhibitions2023": 2,
          "area2022": null,
          "area2023": 0.49,
          "x": 317,
          "y": 305
        },
        {
          "country": "新加坡",
          "exhibitions2022": null,
          "exhibitions2023": 2,
          "area2022": null,
          "area2023": 2.04,
          "x": 902,
          "y": 304
        },
        {
          "country": "澳大利亚",
          "exhibitions2022": null,
          "exhibitions2023": 2,
          "area2022": null,
          "area2023": 2.4,
          "x": 1035,
          "y": 397
        },
        {
          "country": "缅甸",
          "exhibitions2022": null,
          "exhibitions2023": 2,
          "area2022": null,
          "area2023": 1.8,
          "x": 902,
          "y": 263
        },
        {
          "country": "乌兹别克斯坦",
          "exhibitions2022": null,
          "exhibitions2023": 2,
          "area2022": null,
          "area2023": 1.8,
          "x": 752,
          "y": 178
        },
        {
          "country": "马来西亚",
          "exhibitions2022": null,
          "exhibitions2023": 7,
          "area2022": null,
          "area2023": 9.22,
          "x": 908,
          "y": 299
        },
        {
          "country": "泰国",
          "exhibitions2022": null,
          "exhibitions2023": 6,
          "area2022": null,
          "area2023": 3.41,
          "x": 909,
          "y": 268
        },
        {
          "country": "德国",
          "exhibitions2022": null,
          "exhibitions2023": 6,
          "area2022": null,
          "area2023": 0.8,
          "x": 599,
          "y": 131
        },
        {
          "country": "波兰",
          "exhibitions2022": 2,
          "exhibitions2023": 14,
          "area2022": 1.4,
          "area2023": 2.2,
          "x": 612,
          "y": 120,
          "radio": 6,
          "radio1":	0.571428571,
        },
        {
          "country": "白俄罗斯",
          "exhibitions2022": null,
          "exhibitions2023": 1,
          "area2022": null,
          "area2023": 1.44,
          "x": 622,
          "y": 110
        },
        {
          "country": "沙特阿拉伯",
          "exhibitions2022": null,
          "exhibitions2023": 1,
          "area2022": null,
          "area2023": 0.25,
          "x": 743,
          "y": 260
        }
      ],
      activeTooltip: null, // 当前显示 tooltip 的点
      intervalId: null, // 定时器 ID
    };
  },
  mounted() {
    this.startTooltipRotation(); // 初始化启动轮播
  },
  beforeDestroy() {
    this.stopTooltipRotation(); // 清理定时器
  },
  methods: {
    showTable(){
      this.map=!this.map
    },
    showTooltip(index) {
      this.activeTooltip = index;  // 鼠标悬停时，直接显示 tooltip
      this.stopTooltipRotation();  // 鼠标悬停时停止自动轮播
    },
    hideTooltip() {
      if (this.activeTooltip !== null) {
        this.startTooltipRotation();  // 鼠标移开时重新启动轮播
      }
      this.activeTooltip = null;  // 关闭当前的 tooltip
    },
    startTooltipRotation() {
      if (this.intervalId) return; // 如果定时器已经存在，则不重新启动
      let currentIndex = 0;
      this.intervalId = setInterval(() => {
        this.activeTooltip = currentIndex; // 只更新 activeTooltip，保持 points 数组不变
        // 使用 $nextTick 确保在数据变化后，DOM 更新完成
        this.$nextTick(() => {
          console.log('Tooltip updated:', this.activeTooltip);
        });
        currentIndex = (currentIndex + 1) % this.points.length;
      }, 2000); // 每 2 秒轮换一次
    },
    stopTooltipRotation() {
      if (this.intervalId) {
        clearInterval(this.intervalId); // 停止定时器
        this.intervalId = null;
      }
    },
  }
}
</script>

<style scoped>
.data-icon-div{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;
}
.data-icon-img{
  width: 21px;
}
.qq-map-source{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
  color: #979797;
  width: 100%;
}
.map-div-container{
 /* background-image: url("../../assets/images/zhuanti/map-bg.png");
  background-size: 100% 100%;*/
  padding: 0px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}
.map-top-text{
  width: 1262px;
  top: 17px;
  padding: 24px;
  gap: 12px;
  border-radius: 10px;
  border: 1px solid #E6E8F2;
  box-shadow: 12px 12px 0px 0px #EAF3FF;
  background: #FFFFFF;
  color: #17315E;
}
.qq-map-div{
  position: relative; /* 使得红点可以相对定位 */
  height: 500px;
  background-image: url("../../assets/images/zhuanti/qq-map-bg.png");
  background-size: 100% 100%;
  margin: 23px 44px;
  width: 1232px;
}

.red-dot {
  position: absolute;
  width: 20px;
  height: 20px;
  /*background-color: red;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 20px rgba(255, 38, 38, 0.8);*/
  background-image: url("../../assets/images/zhuanti/local-icon.png");
  background-size: 100% 100%;
}
.tooltip {
  position: absolute;
  /*background-color: rgba(255, 255, 255, 1);*/
  background-image: url("../../assets/images/zhuanti/map-tooltip-bg.png");
  background-size: 100% 100%;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 2000;
}
.tooltip-content {
  font-size: 14px;
  color: #333;
  white-space: pre-line; /* 处理换行符 */
  width: 200px;
  z-index: 2000;
}
.country-label{
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #FFFFFF;
}
.yearbz-label{
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
}
.text-label{
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #31F7F7;
}
</style>
