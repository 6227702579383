<template>
  <div class="zhuanti-div-container">
    <zhuanti-title-block :title="'出海资讯'"></zhuanti-title-block>
    <div class="chzx-container">
      <!-- 左侧图片区域 -->
      <div class="card">
        <!-- 图片部分 -->
        <div class="card-image">
          <img src="../../assets/images/zhuanti/chzx-top1.png" alt="城市景观" class="main-image" />
        </div>
        <!-- 文本部分 -->
        <div class="card-content">
          <div class="card-title"><a target="_blank" :href="first.url" class="title-link">{{first.title}}</a></div>
          <div class="card-description">
            {{first.abs}}
          </div>
          <div class="card-bottom">
            <div class="card-site">{{first.site}}</div>
            <div class="card-date">{{first.time.substring(0,10)}}</div>
          </div>

        </div>
      </div>
      <!-- 右侧标题列表区域 -->
      <div class="title-list-container">
        <div class="scrollable-list">
          <div v-for="(item, index) in titleList.slice(1,7)" :key="index" shadow="never" class="title-item">
            <div class="title">
              <a target="_blank" :href="item.url" class="title-link">{{ item.title }}</a>
            </div>
            <div class="metadata">
              <span class="author">{{ item.site }}</span>
              <span class="date">{{ item.time.substring(0,10) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZhuantiTitleBlock from "./ZhuantiTitleBlock";
import api from "../../api";
export default {
  name: "Chzx-block",
  components: {ZhuantiTitleBlock},
  data() {
    return {
      data: null,
      titleList: [
      ],
      first: {
        title: '智海王潮总裁谌立雄：强化创新、完善体系 助力会展业从做大转向做强',
        abs: '在2024企业家博鳌论坛期间，智海王潮传播集团合伙人、总裁谌立雄表示，当前我国正处于从会展大国迈向会展强国的关键阶段，如何强化行业的创新建设、打造完善的发展体系以及国际话语权，或是推动会展业高质量发展的重要方向。',
        url: 'https://www.cnfin.com/hg-lb/detail/20241208/4152902_1.html',
        time: '2024-12-08',
        site: '新华财经'
      }
    }
  },
  created() {
    this.init()
  },
  methods:{
    async init(){
      this.titleList=(await api.listZhanhuiNews({page:0,size:7,abroad: true})).data.content
    }
  }
}
</script>

<style scoped>
/* 布局样式 */
.chzx-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 16px 16px 16px;
  box-sizing: border-box;
}

/* 左侧图片区域 */
.image-container {
  flex: 2;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.card{
  width: 38%;
  margin-right: 30px;
}
.date{
  margin-left: 30px;
}
.main-image {
  width: 100%;
  height: 180px;
  border-radius: 8px;
}

.image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);
  color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.image-overlay h3 {
  margin: 0;
  font-size: 18px;
}

/* 右侧标题列表区域 */
.title-list-container {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.scrollable-list {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
}

.title-item {
  padding: 8px 8px 16px 8px;
  border-bottom: 1px solid #E6E8F2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  text-decoration-skip-ink: none;
  color: #0356AD;
}

.title-link {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  text-decoration-skip-ink: none;
  color: #0356AD;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 520px;
  display: block;
}

.title-link:hover {
  text-decoration: underline;
}
.title{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: #0356AD;
}
.metadata {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #999;
  align-items: center;
}
.author{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  text-decoration-skip-ink: none;
}
.card-title{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: #0356AD;
  padding: 12px 0px 8px 0px;
}
.card-description{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #666666;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-bottom{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.card-site{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #666666;
}
.card-date{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #666666;
}
</style>
